// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { TeamActionTypes } from './constants';
import Team from './model';

const INIT_STATE = {
    teams: undefined,
    loading: false,
    requestStatus: 0,
};

interface TeamActionType {
    type:
        | TeamActionTypes.API_RESPONSE_SUCCESS
        | TeamActionTypes.API_RESPONSE_ERROR
        | TeamActionTypes.GET_TEAMS
        | TeamActionTypes.FIND_TEAM
        | TeamActionTypes.DELETE_TEAM
        | TeamActionTypes.CREATE_TEAM
        | TeamActionTypes.UPDATE_TEAM
        | TeamActionTypes.REGISTER_TEAM
    payload: {
        actionType?: string;
        data?: Team | {} | PaginationResult<Team>;
        error?: string;
    };
}

interface State {
    teams?: PaginationResult<Team>;
    team?: Team;
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const TeamReducer = (state: State = INIT_STATE, action: TeamActionType): any => {
    switch (action.type) {
        case TeamActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TeamActionTypes.GET_TEAMS: {
                    return {
                        ...state,
                        teams: action.payload.data,
                        loading: false,
                    };
                }
                case TeamActionTypes.FIND_TEAM: {
                    return {
                        ...state,
                        team: action.payload.data,
                        loading: false,
                    };
                }
                case TeamActionTypes.DELETE_TEAM: {
                    return {
                        ...state,
                        team: action.payload.data,
                        loading: false,
                    };
                }
                case TeamActionTypes.CREATE_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case TeamActionTypes.UPDATE_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case TeamActionTypes.REGISTER_TEAM: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case TeamActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case TeamActionTypes.GET_TEAMS:
            return { ...state, loading: true, requestStatus: 0};
        case TeamActionTypes.FIND_TEAM:
            return { ...state, loading: true, requestStatus: 0 };
        case TeamActionTypes.DELETE_TEAM:
            return { ...state, loading: true, requestStatus: 0};
        case TeamActionTypes.CREATE_TEAM:
            return { ...state, loading: true, requestStatus: 0};
        case TeamActionTypes.UPDATE_TEAM:
            return { ...state, loading: true, requestStatus: 0};
        case TeamActionTypes.REGISTER_TEAM:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default TeamReducer;
