// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { EncounterActionTypes } from './constants';
import Encounter from './model';

const INIT_STATE = {
    encounters: undefined,
    dayEncounters: undefined,
    loading: false,
    requestStatus: 0,
};

interface EncounterActionType {
    type:
        | EncounterActionTypes.API_RESPONSE_SUCCESS
        | EncounterActionTypes.API_RESPONSE_ERROR
        | EncounterActionTypes.GET_ENCOUNTERS
        | EncounterActionTypes.GET_DAY_ENCOUNTERS
        | EncounterActionTypes.FIND_ENCOUNTER
        | EncounterActionTypes.CREATE_ENCOUNTER
        | EncounterActionTypes.DELETE_ENCOUNTER
        | EncounterActionTypes.IMPORT_ENCOUNTERS
        | EncounterActionTypes.UPDATE_ENCOUNTER
        | EncounterActionTypes.ADD_ENCOUNTER_RESULTS
    payload: {
        actionType?: string;
        data?: Encounter | {} | PaginationResult<Encounter>;
        error?: string;
    };
}

interface State {
    encounters?: PaginationResult<Encounter>;
    dayEncounters?: PaginationResult<Encounter>;
    encounter?: Encounter;
    loading?: boolean;
    requestStatus?: number;
}

const EncounterReducer = (state: State = INIT_STATE, action: EncounterActionType): any => {
    switch (action.type) {
        case EncounterActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EncounterActionTypes.GET_ENCOUNTERS: {
                    return {
                        ...state,
                        encounters: action.payload.data,
                        loading: false,
                    };
                }
                case EncounterActionTypes.GET_DAY_ENCOUNTERS: {
                    return {
                        ...state,
                        dayEncounters: action.payload.data,
                        loading: false,
                    };
                }
                case EncounterActionTypes.FIND_ENCOUNTER: {
                    return {
                        ...state,
                        encounter: action.payload.data,
                        loading: false,
                    };
                }
                case EncounterActionTypes.CREATE_ENCOUNTER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        error: "",
                    };
                }
                case EncounterActionTypes.DELETE_ENCOUNTER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        error: "",
                    };
                }
                case EncounterActionTypes.IMPORT_ENCOUNTERS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        error: "",
                    };
                }
                case EncounterActionTypes.UPDATE_ENCOUNTER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        error: "",
                    };
                }
                case EncounterActionTypes.ADD_ENCOUNTER_RESULTS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        encounter: undefined,
                        error: "",
                    };
                }
                default:
                    return { ...state };
            }

        case EncounterActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case EncounterActionTypes.GET_ENCOUNTERS:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.GET_DAY_ENCOUNTERS:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.FIND_ENCOUNTER:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.CREATE_ENCOUNTER:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.DELETE_ENCOUNTER:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.IMPORT_ENCOUNTERS:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.UPDATE_ENCOUNTER:
            return { ...state, loading: true, requestStatus: 0};
        case EncounterActionTypes.ADD_ENCOUNTER_RESULTS:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default EncounterReducer;
