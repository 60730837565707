// constants
import { LicenseTypeActionTypes } from './constants';
import LicenseType from './model';

export interface LicenseTypeActionType {
    type:
        | LicenseTypeActionTypes.API_RESPONSE_SUCCESS
        | LicenseTypeActionTypes.API_RESPONSE_ERROR
        | LicenseTypeActionTypes.GET_LICENSE_TYPES
    payload: {} | string;
}

// common success
export const licenseTypesApiResponseSuccess = (actionType: string, data: LicenseType | LicenseType[] | {} | []): LicenseTypeActionType => ({
    type: LicenseTypeActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const licenseTypesApiResponseError = (actionType: string, error: string): LicenseTypeActionType => ({
    type: LicenseTypeActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getlicenseTypes = (): LicenseTypeActionType => ({
    type: LicenseTypeActionTypes.GET_LICENSE_TYPES,
    payload: {},
});

