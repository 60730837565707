// constants
import { RFETMLicenseActionTypes } from './constants';
import RFETMLicense from './model';

export interface RFETMLicenseActionType {
    type:
        | RFETMLicenseActionTypes.API_RESPONSE_SUCCESS
        | RFETMLicenseActionTypes.API_RESPONSE_ERROR
        | RFETMLicenseActionTypes.GET_RFETM_LICENSES
    payload: {} | string;
}

// common success
export const RfetmLicensesApiResponseSuccess = (actionType: string, data: RFETMLicense | RFETMLicense[] | {} | []): RFETMLicenseActionType => ({
    type: RFETMLicenseActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const RfetmLicensesApiResponseError = (actionType: string, error: string): RFETMLicenseActionType => ({
    type: RFETMLicenseActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getRfetmLicenses = (licenseId: number): RFETMLicenseActionType => ({
    type: RFETMLicenseActionTypes.GET_RFETM_LICENSES,
    payload: licenseId,
});

