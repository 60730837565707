import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { licenseRolesApiResponseSuccess, licenseRolesApiResponseError } from './actions';

// constants
import { LicenseRoleActionTypes } from './constants';

const api = new APICore();
const BASE_URL = '/license-roles/';

/**
 * Get all licenseRoles
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(licenseRolesApiResponseSuccess(LicenseRoleActionTypes.GET_LICENSE_ROLES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licenseRolesApiResponseError(LicenseRoleActionTypes.GET_LICENSE_ROLES, error));
    }
}

export function* watchGetAllLicenseRoles() {
    yield takeEvery(LicenseRoleActionTypes.GET_LICENSE_ROLES, getAll);
}


function* licenseRolesSaga() {
    yield all([fork(watchGetAllLicenseRoles)]);
}

export default licenseRolesSaga;
