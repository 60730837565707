// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { LeagueActionTypes } from './constants';
import League from './model';

const INIT_STATE = {
    leagues: undefined,
    loading: false,
    requestStatus: 0,
};

interface LeagueActionType {
    type:
        | LeagueActionTypes.API_RESPONSE_SUCCESS
        | LeagueActionTypes.API_RESPONSE_ERROR
        | LeagueActionTypes.GET_LEAGUES
        | LeagueActionTypes.FIND_LEAGUE
        | LeagueActionTypes.CREATE_LEAGUE
        | LeagueActionTypes.UPDATE_LEAGUE
        | LeagueActionTypes.ADD_REFEREE_FEE
    payload: {
        actionType?: string;
        data?: League | {} | PaginationResult<League>;
        error?: string;
    };
}

interface State {
    leagues?: PaginationResult<League>;
    league?: League;
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const LeagueReducer = (state: State = INIT_STATE, action: LeagueActionType): any => {
    switch (action.type) {
        case LeagueActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LeagueActionTypes.GET_LEAGUES: {
                    return {
                        ...state,
                        leagues: action.payload.data,
                        loading: false,
                    };
                }
                case LeagueActionTypes.FIND_LEAGUE: {
                    return {
                        ...state,
                        league: action.payload.data,
                        loading: false,
                    };
                }
                case LeagueActionTypes.CREATE_LEAGUE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LeagueActionTypes.UPDATE_LEAGUE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LeagueActionTypes.ADD_REFEREE_FEE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case LeagueActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case LeagueActionTypes.GET_LEAGUES:
            return { ...state, loading: true, requestStatus: 0};
        case LeagueActionTypes.FIND_LEAGUE:
            return { ...state, loading: true, requestStatus: 0};
        case LeagueActionTypes.CREATE_LEAGUE:
            return { ...state, loading: true, requestStatus: 0};
        case LeagueActionTypes.UPDATE_LEAGUE:
            return { ...state, loading: true, requestStatus: 0};
        case LeagueActionTypes.ADD_REFEREE_FEE:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default LeagueReducer;
