import { put } from 'redux-saga/effects';

// actions
import { logoutUser, authApiResponseError } from '../redux/actions';

export function* mwErrorHandling(error: any) {
    if (error?.message && error?.code) {
        const mwErr = error as mwErrorType;
        switch (mwErr.code) {
            case 401:
                yield put(logoutUser());
                return true;
            case 403:
                yield put(authApiResponseError("", mwErr));
                return true;
            default:
                return false;
        }
    } else return false;
}

export interface mwErrorType {
    code: number,
    message: string,
    extraData?: any
}

export const mwError = (errorCode: number, msg: string, extraData?: any): mwErrorType => {
	return {
		code: errorCode, 
		message: msg,
		extraData
	};
};