// constants
import { UserActionTypes } from './constants';
import User from './model';

export interface UserActionType {
    type:
        | UserActionTypes.API_RESPONSE_SUCCESS
        | UserActionTypes.API_RESPONSE_ERROR
        | UserActionTypes.GET_USERS
        | UserActionTypes.CREATE_USER
        | UserActionTypes.UPDATE_USER
        | UserActionTypes.DELETE_USER
    payload: {} | string;
}

// common success
export const usersApiResponseSuccess = (actionType: string, data: User | User[] | {} | []): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const usersApiResponseError = (actionType: string, error: string): UserActionType => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUsers = (): UserActionType => ({
    type: UserActionTypes.GET_USERS,
    payload: {},
});

export const createUser = (user: User): UserActionType => ({
    type: UserActionTypes.CREATE_USER,
    payload: user,
});

export const updateUser = (user: User): UserActionType => ({
    type: UserActionTypes.UPDATE_USER,
    payload: user,
});

export const deleteUser = (user: User): UserActionType => ({
    type: UserActionTypes.DELETE_USER,
    payload: user,
});
