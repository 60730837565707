// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { RegistrationActionTypes } from './constants';
import Registration from './model';

const INIT_STATE = {
    registrations: undefined,
    availableRegistrations: undefined,
    registration: undefined,
    loading: false,
    requestStatus: 0,
};

interface RegistrationActionType {
    type:
        | RegistrationActionTypes.API_RESPONSE_SUCCESS
        | RegistrationActionTypes.API_RESPONSE_ERROR
        | RegistrationActionTypes.GET_REGISTRATIONS
        | RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS
        | RegistrationActionTypes.FIND_REGISTRATION
        | RegistrationActionTypes.UPDATE_REGISTRATION
        | RegistrationActionTypes.CREATE_REGISTRATION
        | RegistrationActionTypes.DELETE_REGISTRATION
        | RegistrationActionTypes.RESET
    payload: {
        actionType?: string;
        data?: Registration | {};
        error?: string;
    };
}

interface State {
    registrations?: PaginationResult<Registration>;
    availableRegistrations?: PaginationResult<Registration>;
    registration?: Registration;
    loading?: boolean;
    requestStatus?: number;
}

const RegistrationReducer = (state: State = INIT_STATE, action: RegistrationActionType): any => {
    switch (action.type) {
        case RegistrationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RegistrationActionTypes.GET_REGISTRATIONS: {
                    return {
                        ...state,
                        registrations: action.payload.data,
                        loading: false,
                    };
                }
                case RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS: {
                    return {
                        ...state,
                        availableRegistrations: action.payload.data,
                        loading: false,
                    };
                }
                case RegistrationActionTypes.FIND_REGISTRATION: {
                    return {
                        ...state,
                        registration: action.payload.data,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case RegistrationActionTypes.DELETE_REGISTRATION:  {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case RegistrationActionTypes.CREATE_REGISTRATION: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case RegistrationActionTypes.UPDATE_REGISTRATION: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case RegistrationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case RegistrationActionTypes.GET_REGISTRATIONS:
            return { ...state, loading: true, requestStatus: 0};
        case RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS:
            return { ...state, loading: true, requestStatus: 0};
        case RegistrationActionTypes.FIND_REGISTRATION:
            return { ...state, loading: true, requestStatus: 0, registration: undefined};
        case RegistrationActionTypes.UPDATE_REGISTRATION:
            return { ...state, loading: true, requestStatus: 0};
        case RegistrationActionTypes.CREATE_REGISTRATION:
            return { ...state, loading: true, requestStatus: 0};
        case RegistrationActionTypes.DELETE_REGISTRATION:
            return { ...state, loading: true, requestStatus: 0};
        case RegistrationActionTypes.RESET:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default RegistrationReducer;
