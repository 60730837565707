// constants
import { FilterType } from '../../helpers/api/apiCore';
import { OrderActionTypes } from './constants';
import Order from './model';

export interface OrderActionType {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.GET_ORDERS
        | OrderActionTypes.FIND_ORDER
        | OrderActionTypes.UPDATE_ORDER
        | OrderActionTypes.GET_SHOPPING_CARTS
        | OrderActionTypes.GET_STATISTICS
        | OrderActionTypes.RESET
    payload: {} | string;
}

// common success
export const ordersApiResponseSuccess = (actionType: string, data: Order | Order[] | {} | []): OrderActionType => ({
    type: OrderActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ordersApiResponseError = (actionType: string, error: string): OrderActionType => ({
    type: OrderActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getOrders = (filter: FilterType): OrderActionType => ({
    type: OrderActionTypes.GET_ORDERS,
    payload: filter,
});

export const findOrder = (orderId: number): OrderActionType => ({
    type: OrderActionTypes.FIND_ORDER,
    payload: orderId,
});

export const updateOrder = (order: Order): OrderActionType => ({
    type: OrderActionTypes.UPDATE_ORDER,
    payload: order,
});

export const getShoppingCarts = (clubId?: number): OrderActionType => ({
    type: OrderActionTypes.GET_SHOPPING_CARTS,
    payload: {clubId},
});

export const getOrdersStatistics = (): OrderActionType => ({
    type: OrderActionTypes.GET_STATISTICS,
    payload: {},
});

export const resetOrdersData = (): OrderActionType => ({
    type: OrderActionTypes.RESET,
    payload: {},
});