export enum PassActionTypes {
    API_RESPONSE_SUCCESS = '@@passes/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@passes/API_RESPONSE_ERROR',

    GET_PASSES = '@@passes/GET_PASSES',
    FIND_PASS = '@@passes/FIND_PASS',
    UPDATE_PASS = '@@passes/UPDATE_PASS',
    DELETE_PASS = '@@passes/DELETE_PASS',
    CREATE_PASS = '@@passes/CREATE_PASS',
}
