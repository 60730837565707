// constants
import { AgeGroupActionTypes } from './constants';
import AgeGroup from './model';

const INIT_STATE = {
    ageGroups: [],
    loading: false,
    requestStatus: 0,
};

interface AgeGroupActionType {
    type:
        | AgeGroupActionTypes.API_RESPONSE_SUCCESS
        | AgeGroupActionTypes.API_RESPONSE_ERROR
        | AgeGroupActionTypes.GET_AGE_GROUPS
    payload: {
        actionType?: string;
        data?: AgeGroup | {};
        error?: string;
    };
}

interface State {
    ageGroups?: AgeGroup[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const AgeGroupReducer = (state: State = INIT_STATE, action: AgeGroupActionType): any => {
    switch (action.type) {
        case AgeGroupActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AgeGroupActionTypes.GET_AGE_GROUPS: {
                    return {
                        ...state,
                        ageGroups: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AgeGroupActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case AgeGroupActionTypes.GET_AGE_GROUPS:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default AgeGroupReducer;
