import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { tournamentsApiResponseSuccess, tournamentsApiResponseError } from './actions';

// constants
import { TournamentActionTypes } from './constants';
import Tournament from './model';

interface TournamentData {
    payload: Tournament;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/tournaments/';

/**
 * Fetch tournaments in pagination container
 */
 function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'ASC' }];
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(tournamentsApiResponseSuccess(TournamentActionTypes.GET_TOURNAMENTS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(tournamentsApiResponseError(TournamentActionTypes.GET_TOURNAMENTS, error));
    }
}

/**
 * Find a specific tournament
 */
 function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(tournamentsApiResponseSuccess(TournamentActionTypes.FIND_TOURNAMENT, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(tournamentsApiResponseError(TournamentActionTypes.FIND_TOURNAMENT, error));
    }
}

function* createTournament({payload}: TournamentData): SagaIterator {
    try {
		const { name, location, type, minTeamSize, sex, ageGroupsIds, licenseTypesIds, startDate, endDate, openRegistration, closeRegistration, pass, passes, isVisible } = payload;
		const createFields = { name, location, type, minTeamSize, sex, ageGroupsIds, licenseTypesIds, startDate, endDate, openRegistration, closeRegistration, pass, passes, isVisible };
        const response = yield call(api.post, {url: `${BASE_URL}`, data: createFields});
        const created = response.data;
        yield put(tournamentsApiResponseSuccess(TournamentActionTypes.CREATE_TOURNAMENT, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(tournamentsApiResponseError(TournamentActionTypes.CREATE_TOURNAMENT, error));
    }
}

function* updateTournament({payload}: TournamentData): SagaIterator {
    try {
		const { name, location, type, minTeamSize, sex, ageGroupsIds, licenseTypesIds, startDate, endDate, openRegistration, closeRegistration, pass, passes, isVisible } = payload;
		const updateFields = { name, location, type, minTeamSize, sex, ageGroupsIds, licenseTypesIds, startDate, endDate, openRegistration, closeRegistration, pass, passes, isVisible };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(tournamentsApiResponseSuccess(TournamentActionTypes.UPDATE_TOURNAMENT, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(tournamentsApiResponseError(TournamentActionTypes.UPDATE_TOURNAMENT, error));
    }
}

function* deleteTournament({payload}: TournamentData): SagaIterator {
    try {
		const { id } = payload;
        yield call(api.delete, {url: `${BASE_URL}${id}`});
        yield put(tournamentsApiResponseSuccess(TournamentActionTypes.DELETE_TOURNAMENT, {}));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(tournamentsApiResponseError(TournamentActionTypes.DELETE_TOURNAMENT, error));
    }
}

export function* watchGetAllTournaments() {
    yield takeEvery(TournamentActionTypes.GET_TOURNAMENTS, getAll);
}

export function* watchFindOneTournaments() {
    yield takeEvery(TournamentActionTypes.FIND_TOURNAMENT, findOne);
}

export function* watchCreateTournament() {
    yield takeEvery(TournamentActionTypes.CREATE_TOURNAMENT, createTournament);
}

export function* watchUpdateTournament() {
    yield takeEvery(TournamentActionTypes.UPDATE_TOURNAMENT, updateTournament);
}

export function* watchDeleteTournament() {
    yield takeEvery(TournamentActionTypes.DELETE_TOURNAMENT, deleteTournament);
}

function* tournamentsSaga() {
    yield all([fork(watchGetAllTournaments), fork(watchFindOneTournaments), fork(watchUpdateTournament), fork(watchDeleteTournament), fork(watchCreateTournament)]);
}

export default tournamentsSaga;
