// constants
import { TournamentActionTypes } from './constants';
import Tournament from './model';

const INIT_STATE = {
    tournaments: [],
    tournament: undefined,
    loading: false,
    requestStatus: 0,
};

interface TournamentActionType {
    type:
        | TournamentActionTypes.API_RESPONSE_SUCCESS
        | TournamentActionTypes.API_RESPONSE_ERROR
        | TournamentActionTypes.GET_TOURNAMENTS
        | TournamentActionTypes.FIND_TOURNAMENT
        | TournamentActionTypes.CREATE_TOURNAMENT
        | TournamentActionTypes.UPDATE_TOURNAMENT
        | TournamentActionTypes.DELETE_TOURNAMENT
    payload: {
        actionType?: string;
        data?: Tournament | {};
        error?: string;
    };
}

interface State {
    tournaments?: Tournament[] | {};
    tournament?: Tournament;
    loading?: boolean;
    requestStatus?: number;
}

const TournamentReducer = (state: State = INIT_STATE, action: TournamentActionType): any => {
    switch (action.type) {
        case TournamentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TournamentActionTypes.GET_TOURNAMENTS: {
                    return {
                        ...state,
                        tournaments: action.payload.data,
                        loading: false,
                    };
                }
                case TournamentActionTypes.FIND_TOURNAMENT: {
                    return {
                        ...state,
                        tournament: action.payload.data,
                        loading: false,
                    };
                }
                case TournamentActionTypes.CREATE_TOURNAMENT: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        tournament: undefined,
                    };
                }
                case TournamentActionTypes.UPDATE_TOURNAMENT: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        tournament: undefined,
                    };
                }
                case TournamentActionTypes.DELETE_TOURNAMENT: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        tournament: undefined,
                    };
                }
                default:
                    return { ...state };
            }

        case TournamentActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case TournamentActionTypes.GET_TOURNAMENTS:
            return { ...state, loading: true, requestStatus: 0};
        case TournamentActionTypes.FIND_TOURNAMENT:
            return { ...state, loading: true, requestStatus: 0};
        case TournamentActionTypes.CREATE_TOURNAMENT:
            return { ...state, loading: true, requestStatus: 0};
        case TournamentActionTypes.UPDATE_TOURNAMENT:
            return { ...state, loading: true, requestStatus: 0};
        case TournamentActionTypes.DELETE_TOURNAMENT:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default TournamentReducer;
