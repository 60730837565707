// constants
import { LicenseRoleActionTypes } from './constants';
import LicenseRole from './model';

export interface LicenseRoleActionType {
    type:
        | LicenseRoleActionTypes.API_RESPONSE_SUCCESS
        | LicenseRoleActionTypes.API_RESPONSE_ERROR
        | LicenseRoleActionTypes.GET_LICENSE_ROLES
    payload: {} | string;
}

// common success
export const licenseRolesApiResponseSuccess = (actionType: string, data: LicenseRole | LicenseRole[] | {} | []): LicenseRoleActionType => ({
    type: LicenseRoleActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const licenseRolesApiResponseError = (actionType: string, error: string): LicenseRoleActionType => ({
    type: LicenseRoleActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getlicenseRoles = (): LicenseRoleActionType => ({
    type: LicenseRoleActionTypes.GET_LICENSE_ROLES,
    payload: {},
});

