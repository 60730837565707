// constants
import { PaymentActionTypes } from './constants';
import Payment from './model';

export interface PaymentActionType {
    type:
        | PaymentActionTypes.API_RESPONSE_SUCCESS
        | PaymentActionTypes.API_RESPONSE_ERROR
        | PaymentActionTypes.REQUEST_PAYMENT
        | PaymentActionTypes.GET_REDIRECT_PARAMS
        | PaymentActionTypes.GET_INSITE_PARAMS
    payload: {} | string;
}

// common success
export const paymentsApiResponseSuccess = (actionType: string, data: Payment | Payment[] | {} | []): PaymentActionType => ({
    type: PaymentActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const paymentsApiResponseError = (actionType: string, error: string): PaymentActionType => ({
    type: PaymentActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const requestPayment = (payment: Payment): PaymentActionType => ({
    type: PaymentActionTypes.REQUEST_PAYMENT,
    payload: payment,
});

export const getRedirectParams = (payment: any): PaymentActionType => ({
    type: PaymentActionTypes.GET_REDIRECT_PARAMS,
    payload: payment,
});

export const getInSiteParams = (payment: any): PaymentActionType => ({
    type: PaymentActionTypes.GET_INSITE_PARAMS,
    payload: payment,
});
