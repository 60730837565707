export enum ClubActionTypes {
    API_RESPONSE_SUCCESS = '@@clubs/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@clubs/API_RESPONSE_ERROR',

    GET_CLUBS = '@@clubs/GET_CLUBS',
    FIND_CLUB = '@@clubs/FIND_CLUB',
    UPDATE_CLUB = '@@clubs/UPDATE_CLUB',
    DELETE_CLUB = '@@clubs/DELETE_CLUB',
    CREATE_CLUB = '@@clubs/CREATE_CLUB',

    RENEW_CLUB = '@@clubs/RENEW_CLUB',
}
