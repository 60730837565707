// constants
import { FilterType } from '../../helpers/api/apiCore';
import { PassActionTypes } from './constants';
import Pass from './model';

export interface PassActionType {
    type:
        | PassActionTypes.API_RESPONSE_SUCCESS
        | PassActionTypes.API_RESPONSE_ERROR
        | PassActionTypes.GET_PASSES
        | PassActionTypes.UPDATE_PASS
        | PassActionTypes.CREATE_PASS
        | PassActionTypes.DELETE_PASS
    payload: {} | string;
}

// common success
export const passesApiResponseSuccess = (actionType: string, data: Pass | Pass[] | {} | []): PassActionType => ({
    type: PassActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const passesApiResponseError = (actionType: string, error: string): PassActionType => ({
    type: PassActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPasses = (filter: FilterType): PassActionType => ({
    type: PassActionTypes.GET_PASSES,
    payload: filter,
});

export const createPass = (pass: Pass): PassActionType => ({
    type: PassActionTypes.CREATE_PASS,
    payload: pass,
});

export const updatePass = (pass: Pass): PassActionType => ({
    type: PassActionTypes.UPDATE_PASS,
    payload: pass,
});

export const deletePass = (passId: number): PassActionType => ({
    type: PassActionTypes.DELETE_PASS,
    payload: passId,
});
