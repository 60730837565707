const config = {
    // NOTICE THE / AT THE END OF THE API_URL

    // LOCAL
    // WEB_URL: process.env.REACT_APP_API_URL,
    // API_URL: "http://127.0.0.1:3000/",

    // UNUSED
    // WEB_URL: "https://fttcv.mainware.es",
    // API_URL: "https://api.fttcv.mainware.es/",

    // PROD
    WEB_URL: "https://admin.fttcv.com",
    API_URL: "https://api.admin.fttcv.com/",

    // DB
    DB_TIME_DIFF: 4,
};

export default config;
