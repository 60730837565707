export enum UserActionTypes {
    API_RESPONSE_SUCCESS = '@@users/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@users/API_RESPONSE_ERROR',

    GET_USERS = '@@users/GET_USERS',
    FIND_USER = '@@users/FIND_USER',
    UPDATE_USER = '@@users/UPDATE_USER',
    DELETE_USER = '@@users/DELETE_USER',
    CREATE_USER = '@@users/CREATE_USER',
}
