// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { OrderLineActionTypes } from './constants';
import OrderLine from './model';

const INIT_STATE = {
    orderLine: undefined,
    loading: false,
    requestStatus: 0,
};

interface OrderLineActionType {
    type:
        | OrderLineActionTypes.API_RESPONSE_SUCCESS
        | OrderLineActionTypes.API_RESPONSE_ERROR
        | OrderLineActionTypes.DELETE_ORDERLINE
        | OrderLineActionTypes.FIND_ORDERLINE
        | OrderLineActionTypes.CREATE_ORDERLINE
        | OrderLineActionTypes.UPDATE_ORDERLINE
    payload: {
        actionType?: string;
        data?: OrderLine | {} | PaginationResult<OrderLine>;
        error?: string;
    };
}

interface State {
    orderLine?: OrderLine,
    loading?: boolean;
    requestStatus?: number;
}

const OrderLineReducer = (state: State = INIT_STATE, action: OrderLineActionType): any => {
    switch (action.type) {
        case OrderLineActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderLineActionTypes.DELETE_ORDERLINE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case OrderLineActionTypes.FIND_ORDERLINE: {
                    return {
                        ...state,
                        orderLine: action.payload.data,
                        loading: false,
                    };
                }
                case OrderLineActionTypes.CREATE_ORDERLINE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case OrderLineActionTypes.UPDATE_ORDERLINE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderLineActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case OrderLineActionTypes.DELETE_ORDERLINE:
            return { ...state, loading: true, requestStatus: 0};
        case OrderLineActionTypes.FIND_ORDERLINE:
            return { ...state, loading: true, requestStatus: 0};
        case OrderLineActionTypes.CREATE_ORDERLINE:
            return { ...state, loading: true, requestStatus: 0};
        case OrderLineActionTypes.UPDATE_ORDERLINE:
            return { ...state, loading: true, requestStatus: 0, orderLine: undefined};
        default:
            return { ...state };
    }
};

export default OrderLineReducer;
