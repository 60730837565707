export enum TournamentActionTypes {
    API_RESPONSE_SUCCESS = '@@tournaments/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@tournaments/API_RESPONSE_ERROR',

    GET_TOURNAMENTS = '@@tournaments/GET_TOURNAMENTS',
    FIND_TOURNAMENT = '@@tournaments/FIND_TOURNAMENT',
    UPDATE_TOURNAMENT = '@@tournaments/UPDATE_TOURNAMENT',
    DELETE_TOURNAMENT = '@@tournaments/DELETE_TOURNAMENT',
    CREATE_TOURNAMENT = '@@tournaments/CREATE_TOURNAMENT',
}
