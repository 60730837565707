import { ColumnType, SortBy } from './api/apiCore';

export const deepEqual = (a: any, b: any): boolean => JSON.stringify(a) === JSON.stringify(b);

export const defaultOnChangeFunction = (sortBy: SortBy[], setSortBy: any, columns: ColumnType[], sort: any[]) => {
  const columnsToSort = sort?.map((sortItem) => {
    const column = columns.find((x) => sortItem.id === x.accessor);
    return { key: column?.sortName || 'id', order: sortItem.desc ? 'DESC' : 'ASC' };
  });

  if (columnsToSort?.length) {
      if (!deepEqual(sortBy, columnsToSort)) setSortBy(columnsToSort)
  } else setSortBy(undefined);
}

export const sortByToQueryParams = (sortBy?: SortBy[], defaultSortBy?: SortBy[]) => {
  const defaultSorting = (defaultSortBy && defaultSortBy.length) ? defaultSortBy : [{key: 'id', order: 'ASC'}];
  return encodeURIComponent((sortBy?.length ? sortBy : defaultSorting).map(x => `${x.key},${x.order}`).join(';'));
}