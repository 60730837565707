// constants
import { RankingActionTypes } from './constants';
import Ranking from './model';

const INIT_STATE = {
    rankings: [],
    loading: false,
    requestStatus: 0,
};

interface RankingActionType {
    type:
        | RankingActionTypes.API_RESPONSE_SUCCESS
        | RankingActionTypes.API_RESPONSE_ERROR
        | RankingActionTypes.GET_RANKING
        | RankingActionTypes.GET_RANKINGS
        | RankingActionTypes.CREATE_RANKING
        | RankingActionTypes.UPDATE_RANKING
        | RankingActionTypes.DELETE_RANKING
    payload: {
        actionType?: string;
        data?: Ranking | {};
        error?: string;
    };
}

interface State {
    rankings?: Ranking[] | {};
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const RankingReducer = (state: State = INIT_STATE, action: RankingActionType): any => {
    switch (action.type) {
        case RankingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RankingActionTypes.GET_RANKING: {
                    return {
                        ...state,
                        ranking: action.payload.data,
                        loading: false,
                    };
                }
                case RankingActionTypes.GET_RANKINGS: {
                    return {
                        ...state,
                        rankings: action.payload.data,
                        loading: false,
                    };
                }
                case RankingActionTypes.CREATE_RANKING: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        rankings: [],
                    };
                }
                case RankingActionTypes.UPDATE_RANKING: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        rankings: [],
                    };
                }
                case RankingActionTypes.DELETE_RANKING: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        rankings: [],
                    };
                }
                default:
                    return { ...state };
            }

        case RankingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case RankingActionTypes.GET_RANKING:
            return { ...state, loading: true, requestStatus: 0, error: undefined};
        case RankingActionTypes.GET_RANKINGS:
            return { ...state, loading: true, requestStatus: 0, error: undefined};
        case RankingActionTypes.CREATE_RANKING:
            return { ...state, loading: true, requestStatus: 0, error: undefined};
        case RankingActionTypes.UPDATE_RANKING:
            return { ...state, loading: true, requestStatus: 0, error: undefined};
        case RankingActionTypes.DELETE_RANKING:
            return { ...state, loading: true, requestStatus: 0, error: undefined};
        default:
            return { ...state };
    }
};

export default RankingReducer;
