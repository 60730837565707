// constants
import { PersonActionTypes } from './constants';
import Person from './model';

const INIT_STATE = {
    persons: [],
    loading: false,
    requestStatus: 0,
};

interface PersonActionType {
    type:
        | PersonActionTypes.API_RESPONSE_SUCCESS
        | PersonActionTypes.API_RESPONSE_ERROR
        | PersonActionTypes.GET_PERSONS
        | PersonActionTypes.CREATE_PERSON
        | PersonActionTypes.UPDATE_PERSON
    payload: {
        actionType?: string;
        data?: Person | {};
        error?: string;
    };
}

interface State {
    persons?: Person[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const PersonReducer = (state: State = INIT_STATE, action: PersonActionType): any => {
    switch (action.type) {
        case PersonActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PersonActionTypes.GET_PERSONS: {
                    return {
                        ...state,
                        persons: action.payload.data,
                        loading: false,
                    };
                }
                case PersonActionTypes.CREATE_PERSON: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        persons: [],
                    };
                }
                case PersonActionTypes.UPDATE_PERSON: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        persons: [],
                    };
                }
                default:
                    return { ...state };
            }

        case PersonActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case PersonActionTypes.GET_PERSONS:
            return { ...state, loading: true, requestStatus: 0};
        case PersonActionTypes.CREATE_PERSON:
            return { ...state, loading: true, requestStatus: 0};
        case PersonActionTypes.UPDATE_PERSON:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default PersonReducer;
