// constants
import { UserActionTypes } from './constants';
import User from './model';

const INIT_STATE = {
    users: [],
    loading: false,
    requestStatus: 0,
};

interface UserActionType {
    type:
        | UserActionTypes.API_RESPONSE_SUCCESS
        | UserActionTypes.API_RESPONSE_ERROR
        | UserActionTypes.GET_USERS
        | UserActionTypes.CREATE_USER
        | UserActionTypes.UPDATE_USER
        | UserActionTypes.DELETE_USER
    payload: {
        actionType?: string;
        data?: User | {};
        error?: string;
    };
}

interface State {
    users?: User[] | {};
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const UserReducer = (state: State = INIT_STATE, action: UserActionType): any => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS: {
                    return {
                        ...state,
                        users: action.payload.data,
                        loading: false,
                    };
                }
                case UserActionTypes.CREATE_USER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case UserActionTypes.DELETE_USER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case UserActionTypes.GET_USERS:
            return { ...state, loading: true, requestStatus: 0};
        case UserActionTypes.CREATE_USER:
            return { ...state, loading: true, requestStatus: 0};
        case UserActionTypes.UPDATE_USER:
            return { ...state, loading: true, requestStatus: 0};
        case UserActionTypes.DELETE_USER:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default UserReducer;
