// constants
import { PersonActionTypes } from './constants';
import Person from './model';

export interface PersonActionType {
    type:
        | PersonActionTypes.API_RESPONSE_SUCCESS
        | PersonActionTypes.API_RESPONSE_ERROR
        | PersonActionTypes.GET_PERSONS
        | PersonActionTypes.CREATE_PERSON
        | PersonActionTypes.UPDATE_PERSON
    payload: {} | string;
}

// common success
export const personsApiResponseSuccess = (actionType: string, data: Person | Person[] | {} | []): PersonActionType => ({
    type: PersonActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const personsApiResponseError = (actionType: string, error: string): PersonActionType => ({
    type: PersonActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getPersons = (): PersonActionType => ({
    type: PersonActionTypes.GET_PERSONS,
    payload: {},
});

export const createPerson = (person: Person): PersonActionType => ({
    type: PersonActionTypes.CREATE_PERSON,
    payload: person,
});

export const updatePerson = (person: Person): PersonActionType => ({
    type: PersonActionTypes.UPDATE_PERSON,
    payload: person,
});
