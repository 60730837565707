import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType, SortBy } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { ordersApiResponseSuccess, ordersApiResponseError } from './actions';

// constants
import { OrderActionTypes } from './constants';
import Order from './model';

interface OrderData {
    payload: Order;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/orders/';

/**
 * Fetch orders in pagination container
 */
function* getAll({payload}: FilterData): SagaIterator {
    try {        
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder: SortBy[] = [{ key: 'id', order: 'DESC' }];
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});        
        yield put(ordersApiResponseSuccess(OrderActionTypes.GET_ORDERS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ordersApiResponseError(OrderActionTypes.GET_ORDERS, error));
    }
}


/**
 * Find given order
 */
 function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(ordersApiResponseSuccess(OrderActionTypes.FIND_ORDER, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ordersApiResponseError(OrderActionTypes.FIND_ORDER, error));
    }
}

function* updateOrder({payload}: OrderData): SagaIterator {
    try {
		const { discount, comments, /* paid */ } = payload;
		const updateFields = { discount, comments, /* paid */ };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(ordersApiResponseSuccess(OrderActionTypes.UPDATE_ORDER, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ordersApiResponseError(OrderActionTypes.UPDATE_ORDER, error));
    }
}

/**
 * Fetch shopping carts
 */
 function* getShoppingCarts({payload}: any): SagaIterator {
    try {
        const {clubId} = payload;
        const where = {paid: false, clubId};
        const sortBy: SortBy[] = [{key: 'type', order: 'ASC'}];
        const pageSize = 10;
        const currentPage = 1;
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(ordersApiResponseSuccess(OrderActionTypes.GET_SHOPPING_CARTS, response.data?.rows ?? []));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ordersApiResponseError(OrderActionTypes.GET_SHOPPING_CARTS, error));
    }
}

/**
 * Get orders statistics
 */
 function* getOrderStatistics(): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}statistics`});
        yield put(ordersApiResponseSuccess(OrderActionTypes.GET_STATISTICS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ordersApiResponseError(OrderActionTypes.GET_STATISTICS, error));
    }
}

export function* watchGetAllOrders() {
    yield takeEvery(OrderActionTypes.GET_ORDERS, getAll);
}

export function* watchFindOrder() {
    yield takeEvery(OrderActionTypes.FIND_ORDER, findOne);
}

export function* watchUpdateOrder() {
    yield takeEvery(OrderActionTypes.UPDATE_ORDER, updateOrder);
}

export function* watchGetShoppingCarts() {
    yield takeEvery(OrderActionTypes.GET_SHOPPING_CARTS, getShoppingCarts);
}

export function* watchGetStatistics() {
    yield takeEvery(OrderActionTypes.GET_STATISTICS, getOrderStatistics);
}

function* ordersSaga() {
    yield all([fork(watchGetAllOrders), fork(watchFindOrder), fork(watchUpdateOrder), fork(watchGetShoppingCarts), fork(watchGetStatistics)]);
}

export default ordersSaga;
