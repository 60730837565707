// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { LicenseActionTypes } from './constants';
import License, { LicenseStatistics } from './model';

const INIT_STATE = {
    licenses: undefined,
    compatible: undefined,
    license: undefined,
    statistics: undefined,
    loading: false,
    requestStatus: 0,
};

interface LicenseActionType {
    type:
        | LicenseActionTypes.API_RESPONSE_SUCCESS
        | LicenseActionTypes.API_RESPONSE_ERROR
        | LicenseActionTypes.GET_LICENSES
        | LicenseActionTypes.GET_TOURNAMENT_LICENSES
        | LicenseActionTypes.GET_PASS_LICENSES
        | LicenseActionTypes.FIND_LICENSE
        | LicenseActionTypes.CREATE_LICENSE
        | LicenseActionTypes.UPDATE_LICENSE
        | LicenseActionTypes.RENEW_LICENSE
        | LicenseActionTypes.VERIFY_LICENSE
        | LicenseActionTypes.CANCEL_LICENSE
        | LicenseActionTypes.TRANSFER_LICENSE
        | LicenseActionTypes.GET_STATISTICS
    payload: {
        actionType?: string;
        data?: License | {} | PaginationResult<License>;
        error?: string;
    };
}

interface State {
    licenses?: PaginationResult<License>;
    compatible?: PaginationResult<License>;
    license?: License;
    statistics?: LicenseStatistics,
    loading?: boolean;
    requestStatus?: number;
}

const LicenseReducer = (state: State = INIT_STATE, action: LicenseActionType): any => {
    switch (action.type) {
        case LicenseActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LicenseActionTypes.GET_LICENSES: {
                    return {
                        ...state,
                        licenses: action.payload.data,
                        loading: false,
                    };
                }
                case LicenseActionTypes.GET_PASS_LICENSES: {
                    return {
                        ...state,
                        compatible: action.payload.data,
                        loading: false,
                    };
                }
                case LicenseActionTypes.GET_TOURNAMENT_LICENSES: {
                    return {
                        ...state,
                        compatible: action.payload.data,
                        loading: false,
                    };
                }
                case LicenseActionTypes.GET_STATISTICS: {
                    return {
                        ...state,
                        statistics: action.payload.data,
                        loading: false,
                    };
                }
                case LicenseActionTypes.FIND_LICENSE: {
                    return {
                        ...state,
                        license: action.payload.data,
                        loading: false,
                    };
                }
                case LicenseActionTypes.CREATE_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LicenseActionTypes.UPDATE_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LicenseActionTypes.RENEW_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LicenseActionTypes.VERIFY_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LicenseActionTypes.CANCEL_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case LicenseActionTypes.TRANSFER_LICENSE: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        error: undefined,
                    };
                }
                default:
                    return { ...state };
            }

        case LicenseActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case LicenseActionTypes.GET_LICENSES:
            return { ...state, loading: true, requestStatus: 0, license: undefined};
        case LicenseActionTypes.GET_TOURNAMENT_LICENSES:
            return { ...state, loading: true, requestStatus: 0, license: undefined};
        case LicenseActionTypes.GET_PASS_LICENSES:
            return { ...state, loading: true, requestStatus: 0, license: undefined};
        case LicenseActionTypes.FIND_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        case LicenseActionTypes.GET_STATISTICS:
            return { ...state, loading: true, requestStatus: 0, statistics: undefined};
        case LicenseActionTypes.CREATE_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        case LicenseActionTypes.UPDATE_LICENSE:
            return { ...state, loading: true, requestStatus: 0, license: undefined};
        case LicenseActionTypes.VERIFY_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        case LicenseActionTypes.CANCEL_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        case LicenseActionTypes.RENEW_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        case LicenseActionTypes.TRANSFER_LICENSE:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default LicenseReducer;
