import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Person from './person/reducers';
import Ranking from './ranking/reducers';
import User from './user/reducers';
import Club from './club/reducers';
import Category from './category/reducers';
import LicenseRole from './licenseRole/reducers';
import LicenseType from './licenseType/reducers';
import License from './license/reducers';
import AgeGroup from './ageGroup/reducers';
import Payment from './payment/reducers';
import Product from './product/reducers';
import Order from './order/reducers';
import OrderLine from './orderLine/reducers';
import Tournament from './tournament/reducers';
import Pass from './pass/reducers';
import Registration from './registration/reducers';
import Team from './team/reducers';
import Constant from './constant/reducers';
import League from './league/reducers';
import Encounter from './encounter/reducers';
import Match from './match/reducers';
import RFETMLicense from './rfetmLicense/reducers';

export default combineReducers({
    Auth,
    Layout,
    Person,
    Ranking,
    User,
    Club,
    Category,
    LicenseRole,
    LicenseType,
    License,
    AgeGroup,
    Payment,
    Product,
    Order,
    OrderLine,
    Tournament,
    Pass,
    Registration,
    Team,
    Constant,
    League,
    Encounter,
    Match,
    RFETMLicense,
});
