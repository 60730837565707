import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';

// actions
import { rankingsApiResponseSuccess, rankingsApiResponseError } from './actions';

// constants
import { RankingActionTypes } from './constants';
import Ranking from './model';

interface RankingData {
    payload: Ranking;
    type: string;
}
interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/rankings/';


function* getRanking({payload}: RankingData): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload.rankingId ?? 0}`});
        yield put(rankingsApiResponseSuccess(RankingActionTypes.GET_RANKING, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(rankingsApiResponseError(RankingActionTypes.GET_RANKING, error));
    }
}

function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { where } = payload;
        const response = yield call(api.get,  {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}`});
        yield put(rankingsApiResponseSuccess(RankingActionTypes.GET_RANKINGS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(rankingsApiResponseError(RankingActionTypes.GET_RANKINGS, error));
    }
}

function* createRanking({payload}: RankingData): SagaIterator {
    try {
		const { date, rows } = payload;
		const createFields = { date, rows }; // TODO: API expects payload in a "data" field or something like that
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(rankingsApiResponseSuccess(RankingActionTypes.CREATE_RANKING, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(rankingsApiResponseError(RankingActionTypes.CREATE_RANKING, error));
    }
}

function* updateRanking({payload}: RankingData): SagaIterator {
    try {
		const { date, rows } = payload;
		const updateFields = { date, rows };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.rankingId ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(rankingsApiResponseSuccess(RankingActionTypes.UPDATE_RANKING, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(rankingsApiResponseError(RankingActionTypes.UPDATE_RANKING, error));
    }
}

function* deleteRanking({payload}: RankingData): SagaIterator {
    try {
        const response = yield call(api.delete, {url: `${BASE_URL}${payload.rankingId ?? 0}`});
        const deleted = response.data;
        yield put(rankingsApiResponseSuccess(RankingActionTypes.DELETE_RANKING, deleted));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(rankingsApiResponseError(RankingActionTypes.DELETE_RANKING, error));
    }
}

export function* watchGetRanking() {
    yield takeEvery(RankingActionTypes.GET_RANKING, getRanking);
}

export function* watchGetAllRankings() {
    yield takeEvery(RankingActionTypes.GET_RANKINGS, getAll);
}

export function* watchCreateRanking() {
    yield takeEvery(RankingActionTypes.CREATE_RANKING, createRanking);
}

export function* watchUpdateRanking() {
    yield takeEvery(RankingActionTypes.UPDATE_RANKING, updateRanking);
}

export function* watchDeleteRanking() {
    yield takeEvery(RankingActionTypes.DELETE_RANKING, deleteRanking);
}

function* rankingsSaga() {
    yield all([fork(watchGetRanking), fork(watchGetAllRankings), fork(watchCreateRanking), fork(watchUpdateRanking), fork(watchDeleteRanking)]);
}

export default rankingsSaga;
