import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';

interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const NotFoundRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
    const api = new APICore();

    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (api.isUserAuthenticated() === true) {
                    // logged in so redirect to 404 for logged in users
                    return (
                        <Redirect
                            to={{
                                pathname: '/pages/error-404-alt',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                } else {
                    // not logged in so redirect to 404 for general users
                    return (
                        <Redirect
                            to={{
                                pathname: '/error-404',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default NotFoundRoute;
