// constants
import { FilterType } from '../../helpers/api/apiCore';
import { RankingActionTypes } from './constants';
import Ranking from './model';

export interface RankingActionType {
    type:
        | RankingActionTypes.API_RESPONSE_SUCCESS
        | RankingActionTypes.API_RESPONSE_ERROR
        | RankingActionTypes.GET_RANKING
        | RankingActionTypes.GET_RANKINGS
        | RankingActionTypes.CREATE_RANKING
        | RankingActionTypes.UPDATE_RANKING
        | RankingActionTypes.DELETE_RANKING
    payload: {} | string;
}

// common success
export const rankingsApiResponseSuccess = (actionType: string, data: Ranking | Ranking[] | {} | []): RankingActionType => ({
    type: RankingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const rankingsApiResponseError = (actionType: string, error: string): RankingActionType => ({
    type: RankingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getRanking = (ranking: Ranking): RankingActionType => ({
    type: RankingActionTypes.GET_RANKING,
    payload: ranking,
});

export const getRankings = (filter: FilterType): RankingActionType => ({
    type: RankingActionTypes.GET_RANKINGS,
    payload: filter,
});

export const createRanking = (ranking: Ranking): RankingActionType => ({
    type: RankingActionTypes.CREATE_RANKING,
    payload: ranking,
});

export const updateRanking = (ranking: Ranking): RankingActionType => ({
    type: RankingActionTypes.UPDATE_RANKING,
    payload: ranking,
});

export const deleteRanking = (ranking: Ranking): RankingActionType => ({
    type: RankingActionTypes.DELETE_RANKING,
    payload: ranking,
});

