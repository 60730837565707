import jsPDF from "jspdf";
import autoTable, { applyPlugin, Color } from 'jspdf-autotable';
import { formatWithOptions } from 'date-fns/fp';
import es from 'date-fns/locale/es';
import logoDark from '../assets/images/logo.png';
import { addRobotoToDoc } from "../assets/fonts/Roboto-Medium-JSPdf";
import i18n from "../i18n";

export const exportListPDF = (title: string, fileName: string, headers: string[], data: string[][]) => {
    applyPlugin(jsPDF);
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const dateToString = formatWithOptions({ locale: es }, "d 'de' MMMM 'de' yyyy"); // 23 de Marzo de 1984

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    addRobotoToDoc(doc);
    
    doc.setFont('roboto');
    doc.setFontSize(24);

    const vertical_align: "top" | "middle" | "bottom" | undefined = "middle";
    const BLACK: Color = [0,0,0]

    let content: any = {
        startY: 120,
        head: [headers],
        body: data,
        styles: {minCellHeight: 30, valign: vertical_align},
        headStyles: {fontSize: 10, valign: vertical_align, horizontalAlign: 'center', minCellHeight: 36, font: "roboto", fillColor: undefined, textColor: BLACK},
        rowPageBreak: "avoid",
    };

    let logo = new Image();
     

    doc.text(title, marginLeft, 58);
    autoTable(doc, content);

    doc.setFontSize(9);
    const pageCount = (doc.internal as any).getNumberOfPages?.() ?? 0;    
    for(let i = 1; i <= pageCount; i++) { 
        doc.setPage(i); 
        doc.text(dateToString(new Date()), marginLeft, 820);
        doc.text(`${i18n.t('Página')} ${i} de ${pageCount}`, 480, 820);
    }

    doc.setPage(1);
    logo.onload = function() {
        doc.addImage(logo, 'PNG', 400, 18, 163, 60);
        doc.save(fileName)
    };
    logo.crossOrigin = "";  
    logo.src=logoDark;
}