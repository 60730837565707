// constants
import { RFETMLicenseActionTypes } from './constants';
import RFETMLicense from './model';

const INIT_STATE = {
    licenses: [],
    loading: false,
    requestStatus: 0,
};

interface RFETMLicenseActionType {
    type:
        | RFETMLicenseActionTypes.API_RESPONSE_SUCCESS
        | RFETMLicenseActionTypes.API_RESPONSE_ERROR
        | RFETMLicenseActionTypes.GET_RFETM_LICENSES
    payload: {
        actionType?: string;
        data?: RFETMLicense[] | {};
        error?: string;
    };
}

interface State {
    licenses: RFETMLicense[];
    loading?: boolean;
    requestStatus?: number;
    error?: any;
}

const RFETMLicenseReducer = (state: State = INIT_STATE, action: RFETMLicenseActionType): any => {
    switch (action.type) {
        case RFETMLicenseActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RFETMLicenseActionTypes.GET_RFETM_LICENSES: {
                    return {
                        ...state,
                        licenses: action.payload.data,
                        requestStatus: 1,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case RFETMLicenseActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case RFETMLicenseActionTypes.GET_RFETM_LICENSES:
            return { ...state, loading: true, requestStatus: 0, licenses: [], error: undefined};
        default:
            return { ...state };
    }
};

export default RFETMLicenseReducer;
