export enum ProductActionTypes {
    API_RESPONSE_SUCCESS = '@@products/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@products/API_RESPONSE_ERROR',

    GET_PRODUCTS = '@@products/GET_PRODUCTS',
    FIND_PRODUCT = '@@products/FIND_PRODUCT',
    UPDATE_PRODUCT = '@@products/UPDATE_PRODUCT',
    DELETE_PRODUCT = '@@products/DELETE_PRODUCT',
    CREATE_PRODUCT = '@@products/CREATE_PRODUCT',
}
