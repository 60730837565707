// constants
import { FilterType } from '../../helpers/api/apiCore';
import { LicenseActionTypes } from './constants';
import License from './model';

export interface LicenseActionType {
    type:
        | LicenseActionTypes.API_RESPONSE_SUCCESS
        | LicenseActionTypes.API_RESPONSE_ERROR
        | LicenseActionTypes.GET_LICENSES
        | LicenseActionTypes.GET_TOURNAMENT_LICENSES
        | LicenseActionTypes.GET_PASS_LICENSES
        | LicenseActionTypes.FIND_LICENSE
        | LicenseActionTypes.CREATE_LICENSE
        | LicenseActionTypes.UPDATE_LICENSE
        | LicenseActionTypes.VERIFY_LICENSE
        | LicenseActionTypes.CANCEL_LICENSE
        | LicenseActionTypes.RENEW_LICENSE
        | LicenseActionTypes.TRANSFER_LICENSE
        | LicenseActionTypes.GET_STATISTICS
    payload: {} | string;
}

// common success
export const licensesApiResponseSuccess = (actionType: string, data: License | License[] | {} | []): LicenseActionType => ({
    type: LicenseActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const licensesApiResponseError = (actionType: string, error: string): LicenseActionType => ({
    type: LicenseActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getLicenses = (filter: FilterType): LicenseActionType => ({
    type: LicenseActionTypes.GET_LICENSES,
    payload: filter,
});

export const getTournamentCompatibleLicenses = (tournamentId: number, otherClubs: boolean, registered: boolean = false): LicenseActionType => ({
    type: LicenseActionTypes.GET_TOURNAMENT_LICENSES,
    payload: {id: tournamentId, otherClubs, registered},
});

export const getPassCompatibleLicenses = (passId: number, otherClubs: boolean, registered: boolean = false): LicenseActionType => ({
    type: LicenseActionTypes.GET_PASS_LICENSES,
    payload: {id: passId, otherClubs, registered},
});

export const findLicense = (licenseId: number): LicenseActionType => ({
    type: LicenseActionTypes.FIND_LICENSE,
    payload: licenseId,
});

export const createLicense = (license: License): LicenseActionType => ({
    type: LicenseActionTypes.CREATE_LICENSE,
    payload: license,
});

export const updateLicense = (license: License): LicenseActionType => ({
    type: LicenseActionTypes.UPDATE_LICENSE,
    payload: license,
});

export const renewLicense = (license: License): LicenseActionType => ({
    type: LicenseActionTypes.RENEW_LICENSE,
    payload: license,
});

export const transferLicense = (license: License): LicenseActionType => ({
    type: LicenseActionTypes.TRANSFER_LICENSE,
    payload: license,
});

export const verifyLicense = (licenseId: number): LicenseActionType => ({
    type: LicenseActionTypes.VERIFY_LICENSE,
    payload: licenseId,
});

export const cancelLicense = (licenseId: number): LicenseActionType => ({
    type: LicenseActionTypes.CANCEL_LICENSE,
    payload: licenseId,
});

export const getLicensesStatistics = (): LicenseActionType => ({
    type: LicenseActionTypes.GET_STATISTICS,
    payload: {},
});