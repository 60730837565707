import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import NotFoundRoute from './NotFoundRoute';
import Root from './Root';

// models
import { UserRoles } from '../redux/user/model';

// lazy load all the views

///////// fttcv
const DefaultHome = React.lazy(() => import('../pages/home'));
// users
const UserPage = React.lazy(() => import('../pages/users'));
const UserForm = React.lazy(() => import('../pages/users/form'));
// persons
const PersonPage = React.lazy(() => import('../pages/persons'));
const PersonForm = React.lazy(() => import('../pages/persons/form'));
const PersonProfile = React.lazy(() => import('../pages/persons/single'));
// clubs
const ClubPage = React.lazy(() => import('../pages/clubs'));
const ClubForm = React.lazy(() => import('../pages/clubs/form'));
const ClubProfile = React.lazy(() => import('../pages/clubs/single'));
// licenses
const LicensePage = React.lazy(() => import('../pages/licenses'));
const LicenseExportPage = React.lazy(() => import('../pages/licenses/club/certification'));
const LicenseBulkExportPage = React.lazy(() => import('../pages/licenses/admin/bulkExcelExport'));
const RefereeLicenseBulkExportPage = React.lazy(() => import('../pages/home/refereeManager/bulkExcelExport'));
const LicenseForm = React.lazy(() => import('../pages/licenses/form'));
// shopping cart
const ShoppingCartPage = React.lazy(() => import('../pages/shoppingCart'));
const CheckoutPage = React.lazy(() => import('../pages/checkout'));
const RedsysRedirect = React.lazy(() => import('../pages/checkout/components/CardPayments/RedsysRedirect'));
const PaymentFailure = React.lazy(() => import('../pages/checkout/PaymentFailure'));
// products
const ProductsPage = React.lazy(() => import('../pages/products'));
// orders
const OrdersPage = React.lazy(() => import('../pages/orders'));
const OrderInvoice = React.lazy(() => import('../pages/invoice'));
// tournaments
const TournamentsPage = React.lazy(() => import('../pages/tournaments'));
const TournamentsForm = React.lazy(() => import('../pages/tournaments/form'));
const TournamentProfilePage = React.lazy(() => import('../pages/tournaments/single'));
const PassesPage = React.lazy(() => import('../pages/passes'));
const PassesProfilePage = React.lazy(() => import('../pages/passes/profile'));
// leagues
const LeaguesAdminPage = React.lazy(() => import('../pages/leagues'));
const LeaguesForm = React.lazy(() => import('../pages/leagues/form'));
const LeagueSingleAdminPage = React.lazy(() => import('../pages/leagues/single'));
// encounters
const EncounterResultsForm = React.lazy(() => import('../pages/encounters/form/ResultsForm'));
const EncounterListPage = React.lazy(() => import('../pages/encounters'));
const EncounterTemplateDownloadPage = React.lazy(() => import('../pages/encounters/templateExport'));
// teams
const TeamsAdminPage = React.lazy(() => import('../pages/teams'));
const TeamBulkExportPage = React.lazy(() => import('../pages/teams/admin/bulkExcelExport'));
// settings
const SettingsPage = React.lazy(() => import('../pages/settings'));
// // constants
const ConstantPage = React.lazy(() => import('../pages/settings/constants/single'));
// ranking
const RankingPage = React.lazy(() => import('../pages/rankings'));

// PUBLIC
// ranking
const RankingPublicPage = React.lazy(() => import('../pages/public/ranking'));
// league results
const ligasHomePage = React.lazy(() => import('../pages/public/leagues'));
const ligaPage = React.lazy(() => import('../pages/public/leagues/single'));
const teamPage = React.lazy(() => import('../pages/public/teams'));
const playerPage = React.lazy(() => import('../pages/public/players'));

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const SignInSignUp = React.lazy(() => import('../pages/auth/SignInSignUp'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));

/////////// TEMPLATE
// auth2
const Login2 = React.lazy(() => import('../pages/auth2/Login2'));
const Logout2 = React.lazy(() => import('../pages/auth2/Logout2'));
const Register2 = React.lazy(() => import('../pages/auth2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/auth2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/auth2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/auth2/LockScreen2'));
const SignInSignUp2 = React.lazy(() => import('../pages/auth2/SignInSignUp2'));

// landing
const Landing = React.lazy(() => import('../pages/landing/'));

// dashboard
const Dashboard1 = React.lazy(() => import('../pages/dashboard/Dashboard1/'));
const Dashboard2 = React.lazy(() => import('../pages/dashboard/Dashboard2/'));
const Dashboard3 = React.lazy(() => import('../pages/dashboard/Dashboard3/'));
const Dashboard4 = React.lazy(() => import('../pages/dashboard/Dashboard4/'));

// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar/'));
const Projects = React.lazy(() => import('../pages/apps/Projects/'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail/'));
const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));
// - chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));
// - ecommece pages
const EcommerceDashboard = React.lazy(() => import('../pages/apps/Ecommerce/Dashboard/'));
const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
const ProductEdit = React.lazy(() => import('../pages/apps/Ecommerce/ProductEdit'));
const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout'));
// - crm pages
const CRMDashboard = React.lazy(() => import('../pages/apps/CRM/Dashboard/'));
const CRMContacts = React.lazy(() => import('../pages/apps/CRM/Contacts/'));
const Opportunities = React.lazy(() => import('../pages/apps/CRM/Opportunities/'));
const CRMLeads = React.lazy(() => import('../pages/apps/CRM/Leads/'));
const CRMCustomers = React.lazy(() => import('../pages/apps/CRM/Customers/'));
// - email
const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
// - social
const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// - companies
const Companies = React.lazy(() => import('../pages/apps/Companies/'));
// - tasks
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('../pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));
// -contacts
const ContactsList = React.lazy(() => import('../pages/apps/Contacts/List/'));
const ContactsProfile = React.lazy(() => import('../pages/apps/Contacts/Profile/'));
// -tickets
const TicketsList = React.lazy(() => import('../pages/apps/Tickets/List/'));
const TicketsDetails = React.lazy(() => import('../pages/apps/Tickets/Details/'));
// - file
const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// extra pages
const Starter = React.lazy(() => import('../pages/other/Starter'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));
const Sitemap = React.lazy(() => import('../pages/other/Sitemap/'));
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error404Two = React.lazy(() => import('../pages/error/Error404Two'));
const Error404Alt = React.lazy(() => import('../pages/error/Error404Alt'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const Error500Two = React.lazy(() => import('../pages/error/Error500Two'));
// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const SearchResults = React.lazy(() => import('../pages/other/SearchResults/'));
const Upcoming = React.lazy(() => import('../pages/other/Upcoming'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Gallery = React.lazy(() => import('../pages/other/Gallery/'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));

// uikit
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Portlets = React.lazy(() => import('../pages/uikit/Portlets'));
const TabsAccordions = React.lazy(() => import('../pages/uikit/TabsAccordions'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('../pages/uikit/Placeholders'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Images = React.lazy(() => import('../pages/uikit/Images'));
const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const TooltipsPopovers = React.lazy(() => import('../pages/uikit/TooltipsPopovers'));
const GeneralUI = React.lazy(() => import('../pages/uikit/GeneralUI'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const NestableList = React.lazy(() => import('../pages/uikit/NestableList'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Animation = React.lazy(() => import('../pages/uikit/Animation'));
const TourPage = React.lazy(() => import('../pages/uikit/TourPage'));
const SweetAlerts = React.lazy(() => import('../pages/uikit/SweetAlerts'));
const LoadingButtons = React.lazy(() => import('../pages/uikit/LoadingButtons'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// icons
const TwoToneIcons = React.lazy(() => import('../pages/icons/TwoToneIcons/'));
const FeatherIcons = React.lazy(() => import('../pages/icons/FeatherIcons/'));
const Dripicons = React.lazy(() => import('../pages/icons/Dripicons/'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons/'));
const FontAwesomeIcons = React.lazy(() => import('../pages/icons/FontAwesomeIcons/'));
const ThemifyIcons = React.lazy(() => import('../pages/icons/ThemifyIcons/'));
const SimpleLineIcons = React.lazy(() => import('../pages/icons/SimpleLineIcons/'));
const WeatherIcons = React.lazy(() => import('../pages/icons/WeatherIcons/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// charts
const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: UserRoles[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/dashboard',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: '/home',
            name: 'Home',
            component: DefaultHome,
            route: PrivateRoute,
        },
        {
            path: '/dashboard-1',
            name: 'Dashboard 1',
            component: Dashboard1,
            route: PrivateRoute,
        },
        {
            path: '/dashboard-2',
            name: 'Dashboard 2',
            component: Dashboard2,
            route: PrivateRoute,
        },
        {
            path: '/dashboard-3',
            name: 'Dashboard 3',
            component: Dashboard3,
            route: PrivateRoute,
        },
        {
            path: '/dashboard-4',
            name: 'Dashboard 4',
            component: Dashboard4,
            route: PrivateRoute,
        },
    ],
};

const calendarAppRoutes: RoutesProps = {
    path: '/apps/calendar',
    name: 'Calendar',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'calendar',
    component: CalendarApp,
    header: 'Apps',
};

const rankingRoutes: RoutesProps = {
    path: '/ranking',
    name: 'Ranking',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: RankingPage,
    children: [
        // {
        //     path: '/users/form',
        //     name: 'Users Form',
        //     route: PrivateRoute,
        //     roles: [UserRoles.ADMIN],
        //     component: UserForm,
        // },
        {
            path: '/rankings',
            name: 'Ranking',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: RankingPage,
        },
    ],
};

const userRoutes: RoutesProps = {
    path: '/users',
    name: 'Users',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: UserPage,
    children: [
        {
            path: '/users/form',
            name: 'Users Form',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: UserForm,
        },
        {
            path: '/users',
            name: 'Users',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: UserPage,
        },
    ],
};


// TODO: FIX: @martin If I define this as children of settings I get a 404 when accesing "parent" page... :/
const constantsSettingsRoutes: RoutesProps = {
    path: '/constants/', // <- Not used...
    name: 'Constant Page',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: ConstantPage,
    children: [
        {
            path: '/constants/:constant_id',
            name: 'Constant Detail Page',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: ConstantPage,
        },
        {
            path: '/constants',
            name: 'Constant Page',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: SettingsPage,
        },
    ],
};

const personRoutes: RoutesProps = {
    path: '/persons',
    name: 'Persons',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: PersonPage,
    children: [
        {
            path: '/persons/form/:person_id',
            name: 'Persons Form',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: PersonForm,
        },
        {
            path: '/persons/:person_id',
            name: 'Person Profile',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: PersonProfile,
        },
        {
            path: '/persons',
            name: 'Persons',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: PersonPage,
        },
    ]
};

const clubRoutes: RoutesProps = {
    path: '/clubs',
    name: 'Clubs',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: ClubPage,
    children: [
        {
            path: '/clubs/form/:club_id',
            name: 'Clubs Form',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: ClubForm,
        },
        {
            path: '/clubs/:club_id',
            name: 'Club Info',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: ClubProfile,
        },
        {
            path: '/clubs',
            name: 'Clubs',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: ClubPage,
        },
    ]
};


const licenseRoutes: RoutesProps = {
    path: '/licenses',
    name: 'Licencias',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN, UserRoles.CLUB],
    component: LicensePage,
    children: [
        {
            path: '/licenses/form/:license_id',
            name: 'Licencias Form',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.REFEREE_MANAGER, UserRoles.CLUB],
            component: LicenseForm,
        },
        {
            path: '/licenses/certification',
            name: 'Certificación Licencias',
            route: PrivateRoute,
            roles: [UserRoles.CLUB],
            component: LicenseExportPage,
        },
        {
            path: '/licenses/export/referees',
            name: 'Exportar Licencias de arbitros',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.REFEREE_MANAGER],
            component: RefereeLicenseBulkExportPage,
        },
        {
            path: '/licenses/export',
            name: 'Exportar Licencias',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: LicenseBulkExportPage,
        },
        {
            path: '/licenses',
            name: 'Licencias',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB],
            component: LicensePage,
        },
    ]
};

const productRoutes: RoutesProps = {
    path: '/products',
    name: 'Products',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: ProductsPage,
};

const orderRoutes: RoutesProps = {
    path: '/orders',
    name: 'Facturas',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN, UserRoles.CLUB],
    component: OrdersPage,
    children: [
        {
            path: '/orders/:order_id',
            name: 'Order info',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.REFEREE],
            component: OrderInvoice,
        },
        {
            path: '/orders',
            name: 'Facturas',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.REFEREE],
            component: OrdersPage,
        },
    ]
};

const checkoutRoutes: RoutesProps = {
    path: '/checkout',
    name: 'Pago',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: CheckoutPage,
    children: [
        {
            path: '/shopping-carts/:type',
            name: 'Carritos',
            route: PrivateRoute,
            roles: [UserRoles.CLUB, UserRoles.REFEREE],
            component: ShoppingCartPage,
        },
        {
            path: '/checkout/:type',
            name: 'Pago',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.REFEREE],
            component: CheckoutPage,
        },
        {
            path: '/redsysRedirect',
            name: 'Redsys Redirect',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.REFEREE],
            component: RedsysRedirect,
        },
        {
            path: '/payment-failure',
            name: 'Payment Failure',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.REFEREE],
            component: PaymentFailure,
        },
    ]
};

const tournamentRoutes: RoutesProps = {
    path: '/tournaments',
    name: 'Torneos',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN, UserRoles.CLUB],
    component: TournamentsPage,
    children: [
        {
            path: '/tournaments/form/:tournament_id',
            name: 'Torneos Form',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: TournamentsForm,
        },
        {
            path: '/tournaments/:tournament_id',
            name: 'Torneo Info',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB],
            component: TournamentProfilePage,
        },
        {
            path: '/tournaments',
            name: 'Torneos',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB],
            component: TournamentsPage,
        },
        {
            path: '/passes/:pass_id',
            name: 'Pases Comprados',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: PassesProfilePage,
        },
        {
            path: '/passes',
            name: 'Pases',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB],
            component: PassesPage,
        },
    ]
};

const leagueRoutes: RoutesProps = {
    path: '/leagues',
    name: 'Ligas',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: LeaguesAdminPage,
    children: [
        {
            path: '/leagues/form/:league_id',
            name: 'Form Ligas',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: LeaguesForm,
        },
        {
            path: '/leagues/:league_id',
            name: 'Info Liga',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: LeagueSingleAdminPage,
        },
        {
            path: '/leagues',
            name: 'Ligas',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: LeaguesAdminPage,
        },
    ]
};

const teamRoutes: RoutesProps = {
    path: '/teams',
    name: 'Equipos',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    component: TeamsAdminPage,
    children: [
        {
            path: '/teams/export',
            name: 'Exportar Equipos',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: TeamBulkExportPage,
        },
        {
            path: '/teams',
            name: 'Equipos',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN],
            component: TeamsAdminPage,
        },
    ]
};

const encounterRoutes: RoutesProps = {
    path: '/encounters',
    name: 'Encounters',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.CLUB_ASSISTANT, UserRoles.REFEREE],
    component: EncounterListPage,
    children: [
        {
            path: '/encounters/form/:encounterId',
            name: 'Form Encounter',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.CLUB_ASSISTANT, UserRoles.REFEREE],
            component: EncounterResultsForm,
        },
        {
            path: '/encounters/template/:encounterId',
            name: 'Encounter Template',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.CLUB_ASSISTANT, UserRoles.REFEREE],
            component: EncounterTemplateDownloadPage,
        },
        {
            path: '/encounters',
            name: 'Encounter List',
            route: PrivateRoute,
            roles: [UserRoles.ADMIN, UserRoles.CLUB, UserRoles.CLUB_ASSISTANT, UserRoles.REFEREE],
            component: EncounterListPage,
        },
    ]
};

// public routes
const resultsPublicRoutes = [
    {
        path: '/ligas/:startYear-:endYear/jugadores/:license_id',
        name: 'Jugadores de Ligas',
        component: playerPage,
        route: Route,
    },
    {
        path: '/ligas/:startYear-:endYear',
        name: 'Resultados de Ligas',
        component: ligasHomePage,
        route: Route,
    },
    {
        path: '/ligas/:league_id/jornada/:jornada_id',
        name: 'Resultados en una Jornada de Ligas',
        component: ligaPage,
        route: Route,
    },
    {
        path: '/ligas/:league_id/equipos/:team_id',
        name: 'Equipos de Ligas',
        component: teamPage,
        route: Route,
    },
    {
        path: '/ligas/:league_id',
        name: 'Resultados de Ligas',
        component: ligaPage,
        route: Route,
    },
    {
        path: '/ligas',
        name: 'Resultados de Ligas',
        component: ligasHomePage,
        route: Route,
    },
    {
        path: '/ranking/:startYear-:endYear',
        name: 'Ranking',
        route: Route,
        component: RankingPublicPage,
    },
    {
        path: '/ranking',
        name: 'Ranking',
        route: Route,
        component: RankingPublicPage,
    },
];

const chatAppRoutes = {
    path: '/apps/chat',
    name: 'Chat',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'message-square',
    component: ChatApp,
};

const ecommerceAppRoutes = {
    path: '/apps/ecommerce',
    name: 'eCommerce',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'shopping-cart',
    children: [
        {
            path: '/apps/ecommerce/dashboard',
            name: 'Products',
            component: EcommerceDashboard,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/products',
            name: 'Products',
            component: EcommerceProducts,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/product-details',
            name: 'Product Details',
            component: ProductDetails,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/edit-product',
            name: 'Product Edit',
            component: ProductEdit,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/customers',
            name: 'Customers',
            component: Customers,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/orders',
            name: 'Orders',
            component: Orders,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/order/details',
            name: 'Order Details',
            component: OrderDetails,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/sellers',
            name: 'Sellers',
            component: Sellers,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/shopping-cart',
            name: 'Shopping Cart',
            component: Cart,
            route: PrivateRoute,
        },
        {
            path: '/apps/ecommerce/checkout',
            name: 'Checkout',
            component: Checkout,
            route: PrivateRoute,
        },
    ],
};

const crmAppRoutes = {
    path: '/apps/crm',
    name: 'CRM',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'users',
    children: [
        {
            path: '/apps/crm/dashboard',
            name: 'Dashboard',
            component: CRMDashboard,
            route: PrivateRoute,
        },
        {
            path: '/apps/crm/contacts',
            name: 'Contacts',
            component: CRMContacts,
            route: PrivateRoute,
        },
        {
            path: '/apps/crm/opportunities',
            name: 'Opportunities',
            component: Opportunities,
            route: PrivateRoute,
        },
        {
            path: '/apps/crm/leads',
            name: 'Leads',
            component: CRMLeads,
            route: PrivateRoute,
        },
        {
            path: '/apps/crm/customers',
            name: 'Customers',
            component: CRMCustomers,
            route: PrivateRoute,
        },
    ],
};

const emailAppRoutes = {
    path: '/apps/email',
    name: 'Email',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'mail',
    children: [
        {
            path: '/apps/email/inbox',
            name: 'Inbox',
            component: Inbox,
            route: PrivateRoute,
        },
        {
            path: '/apps/email/details',
            name: 'Email Details',
            component: EmailDetail,
            route: PrivateRoute,
        },
        {
            path: '/apps/email/compose',
            name: 'Compose Email',
            component: EmailCompose,
            route: PrivateRoute,
        },
    ],
};

const socialAppRoutes = {
    path: '/apps/social-feed',
    name: 'Social Feed',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'rss',
    component: SocialFeed,
};

const companiesAppRoutes = {
    path: '/apps/companies',
    name: 'Companies',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'activity',
    component: Companies,
};

const projectAppRoutes = {
    path: '/apps/projects',
    name: 'Projects',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'uil-briefcase',

    children: [
        {
            path: '/apps/projects/list',
            name: 'List',
            component: Projects,
            route: PrivateRoute,
        },
        {
            path: '/apps/projects/:id/details',
            name: 'Detail',
            component: ProjectDetail,
            route: PrivateRoute,
        },
        {
            path: '/apps/projects/create',
            name: 'Create Project',
            component: ProjectForm,
            route: PrivateRoute,
        },
    ],
};

const taskAppRoutes = {
    path: '/apps/tasks',
    name: 'Tasks',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'clipboard',
    children: [
        {
            path: '/apps/tasks/list',
            name: 'Task List',
            component: TaskList,
            route: PrivateRoute,
        },
        {
            path: '/apps/tasks/details',
            name: 'Task List',
            component: TaskDetails,
            route: PrivateRoute,
        },
        {
            path: '/apps/tasks/kanban',
            name: 'Kanban',
            component: Kanban,
            route: PrivateRoute,
        },
    ],
};

const contactsRoutes = {
    path: '/apps/contacts',
    name: 'Contacts',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'book',
    children: [
        {
            path: '/apps/contacts/list',
            name: 'Task List',
            component: ContactsList,
            route: PrivateRoute,
        },
        {
            path: '/apps/contacts/profile',
            name: 'Profile',
            component: ContactsProfile,
            route: PrivateRoute,
        },
    ],
};

const ticketsRoutes = {
    path: '/apps/tickets',
    name: 'Tickets',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'aperture',
    children: [
        {
            path: '/apps/tickets/list',
            name: 'List',
            component: TicketsList,
            route: PrivateRoute,
        },
        {
            path: '/apps/tickets/details',
            name: 'Details',
            component: TicketsDetails,
            route: PrivateRoute,
        },
    ],
};

const fileAppRoutes = {
    path: '/apps/file-manager',
    name: 'File Manager',
    route: PrivateRoute,
    roles: [UserRoles.ADMIN],
    icon: 'folder-plus',
    component: FileManager,
};

const appRoutes = [
    calendarAppRoutes,
    chatAppRoutes,
    ecommerceAppRoutes,
    crmAppRoutes,
    emailAppRoutes,
    socialAppRoutes,
    companiesAppRoutes,
    projectAppRoutes,
    taskAppRoutes,
    contactsRoutes,
    ticketsRoutes,
    fileAppRoutes,
];

// pages
const extrapagesRoutes = {
    path: '/pages',
    name: 'Pages',
    icon: 'package',
    header: 'Custom',
    children: [
        {
            path: '/pages/starter',
            name: 'Starter',
            component: Starter,
            route: PrivateRoute,
        },
        {
            path: '/pages/timeline',
            name: 'Timeline',
            component: Timeline,
            route: PrivateRoute,
        },
        {
            path: '/pages/sitemap',
            name: 'Sitemap',
            component: Sitemap,
            route: PrivateRoute,
        },
        {
            path: '/pages/invoice',
            name: 'Invoice',
            component: Invoice,
            route: PrivateRoute,
        },
        {
            path: '/pages/faq',
            name: 'FAQ',
            component: FAQ,
            route: PrivateRoute,
        },
        {
            path: '/pages/serach-results',
            name: 'Search Results',
            component: SearchResults,
            route: PrivateRoute,
        },
        {
            path: '/pages/pricing',
            name: 'Pricing',
            component: Pricing,
            route: PrivateRoute,
        },
        {
            path: '/pages/gallery',
            name: 'Gallery',
            component: Gallery,
            route: PrivateRoute,
        },
        {
            path: '/pages/error-404-alt',
            name: 'Error - 404-alt',
            component: Error404Alt,
            route: PrivateRoute,
        },
    ],
};

// ui
const uiRoutes = {
    path: '/ui',
    name: 'Components',
    icon: 'pocket',
    header: 'UI Elements',
    children: [
        {
            path: '/ui/base',
            name: 'Base UI',
            children: [
                {
                    path: '/ui/buttons',
                    name: 'Buttons',
                    component: Buttons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/cards',
                    name: 'Cards',
                    component: Cards,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/avatars',
                    name: 'Avatars',
                    component: Avatars,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/portlets',
                    name: 'Portlets',
                    component: Portlets,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tabs-accordions',
                    name: 'Tabs & Accordions',
                    component: TabsAccordions,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/progress',
                    name: 'Progress',
                    component: Progress,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/modals',
                    name: 'Modals',
                    component: Modals,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/notifications',
                    name: 'Notifications',
                    component: Notifications,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/offcanvas',
                    name: 'Offcanvas',
                    component: Offcanvases,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/placeholders',
                    name: 'Placeholders',
                    component: Placeholders,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/spinners',
                    name: 'Spinners',
                    component: Spinners,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/images',
                    name: 'Images',
                    component: Images,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/carousel',
                    name: 'Carousel',
                    component: Carousels,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/listgroups',
                    name: 'List Groups',
                    component: ListGroups,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/embedvideo',
                    name: 'EmbedVideo',
                    component: EmbedVideo,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/dropdowns',
                    name: 'Dropdowns',
                    component: Dropdowns,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/ribbons',
                    name: 'Ribbons',
                    component: Ribbons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tooltips-popovers',
                    name: 'Tooltips & Popovers',
                    component: TooltipsPopovers,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/typography',
                    name: 'Typography',
                    component: Typography,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/grid',
                    name: 'Grid',
                    component: Grid,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/general',
                    name: 'General UI',
                    component: GeneralUI,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/extended',
            name: 'Extended UI',
            children: [
                {
                    path: '/extended-ui/nestable',
                    name: 'Nestable List',
                    component: NestableList,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/dragdrop',
                    name: 'Drag and Drop',
                    component: DragDrop,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/rangesliders',
                    name: 'Range Sliders',
                    component: RangeSliders,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/animation',
                    name: 'Animation',
                    component: Animation,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/sweet-alert',
                    name: 'Sweet Alert',
                    component: SweetAlerts,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/tour',
                    name: 'Tour Page',
                    component: TourPage,
                    route: PrivateRoute,
                },
                {
                    path: '/extended-ui/loading-buttons',
                    name: 'Loading Buttons',
                    component: LoadingButtons,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/two-tone',
                    name: 'Two Tone Icons',
                    component: TwoToneIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/dripicons',
                    name: 'Dripicons',
                    component: Dripicons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/mdi',
                    name: 'Material Design',
                    component: MDIIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/font-awesome',
                    name: 'Font Awesome 5',
                    component: FontAwesomeIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/themify',
                    name: 'Themify',
                    component: ThemifyIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/simple-line',
                    name: 'Simple Line Icons',
                    component: SimpleLineIcons,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/icons/weather',
                    name: 'Weather Icons',
                    component: WeatherIcons,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/forms',
            name: 'Forms',
            children: [
                {
                    path: '/ui/forms/basic',
                    name: 'Basic Elements',
                    component: BasicForms,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/advanced',
                    name: 'Form Advanced',
                    component: FormAdvanced,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/validation',
                    name: 'Form Validation',
                    component: FormValidation,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/wizard',
                    name: 'Form Wizard',
                    component: FormWizard,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/upload',
                    name: 'File Upload',
                    component: FileUpload,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/forms/editors',
                    name: 'Editors',
                    component: Editors,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/tables',
            name: 'Tables',
            children: [
                {
                    path: '/ui/tables/basic',
                    name: 'Basic',
                    component: BasicTables,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/tables/advanced',
                    name: 'Advanced',
                    component: AdvancedTables,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/charts',
            name: 'Charts',
            children: [
                {
                    path: '/ui/charts/apex',
                    name: 'Apex',
                    component: ApexChart,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/charts/chartjs',
                    name: 'Chartjs',
                    component: ChartJs,
                    route: PrivateRoute,
                },
            ],
        },
        {
            path: '/ui/maps',
            name: 'Maps',
            children: [
                {
                    path: '/ui/googlemaps',
                    name: 'Google Maps',
                    component: GoogleMaps,
                    route: PrivateRoute,
                },
                {
                    path: '/ui/vectorMaps',
                    name: 'Google Maps',
                    component: VectorMaps,
                    route: PrivateRoute,
                },
            ],
        },
    ],
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/signin-signup',
        name: 'SignIn-SignUp',
        component: SignInSignUp,
        route: Route,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/auth/login2',
        name: 'Login2',
        component: Login2,
        route: Route,
    },
    {
        path: '/auth/logout2',
        name: 'Logout2',
        component: Logout2,
        route: Route,
    },
    {
        path: '/auth/register2',
        name: 'Register2',
        component: Register2,
        route: Route,
    },
    {
        path: '/auth/confirm2',
        name: 'Confirm2',
        component: Confirm2,
        route: Route,
    },
    {
        path: '/auth/forget-password2',
        name: 'Forget Password2',
        component: ForgetPassword2,
        route: Route,
    },
    {
        path: '/auth/signin-signup2',
        name: 'SignIn-SignUp2',
        component: SignInSignUp2,
        route: Route,
    },
    {
        path: '/auth/lock-screen2',
        name: 'Lock Screen2',
        component: LockScreen2,
        route: Route,
    },
];

// public routes
const otherPublicRoutes = [
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-404-two',
        name: 'Error - 404 Two',
        component: Error404Two,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
    {
        path: '/error-500-two',
        name: 'Error - 500 Two',
        component: Error500Two,
        route: Route,
    },
    {
        path: '/upcoming',
        name: 'Coming Soon',
        component: Upcoming,
        route: Route,
    },
];

const notFoundRoute = {
    path: '*',
    name: 'Error - 404',
    route: NotFoundRoute,
};

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes, extrapagesRoutes, uiRoutes, rankingRoutes, userRoutes, personRoutes, constantsSettingsRoutes, clubRoutes, licenseRoutes, checkoutRoutes, productRoutes, orderRoutes, tournamentRoutes, leagueRoutes, encounterRoutes, teamRoutes, notFoundRoute];
const publicRoutes = [...authRoutes, ...otherPublicRoutes, ...resultsPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
