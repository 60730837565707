import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { leaguesApiResponseSuccess, leaguesApiResponseError } from './actions';

// constants
import { LeagueActionTypes } from './constants';
import League, { RefereeFeeData } from './model';

interface LeagueData {
    payload: League;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/leagues/';



function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'DESC' }];
        const response = yield call(api.get, {url:  `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(leaguesApiResponseSuccess(LeagueActionTypes.GET_LEAGUES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(leaguesApiResponseError(LeagueActionTypes.GET_LEAGUES, error));
    }
}

function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(leaguesApiResponseSuccess(LeagueActionTypes.FIND_LEAGUE, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(leaguesApiResponseError(LeagueActionTypes.FIND_LEAGUE, error));
    }
}

function* createLeague({payload}: LeagueData): SagaIterator {
    try {
		const { name, groupId, sex, settings, categoryId } = payload;
		const createFields = { name, groupId, sex, settings, categoryId };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(leaguesApiResponseSuccess(LeagueActionTypes.CREATE_LEAGUE, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(leaguesApiResponseError(LeagueActionTypes.CREATE_LEAGUE, error));
    }
}

function* updateLeague({payload}: LeagueData): SagaIterator {
    try {
		const { name, groupId, sex, settings, ranking, categoryId } = payload;
		const updateFields = { name, groupId, sex, settings, ranking, categoryId };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(leaguesApiResponseSuccess(LeagueActionTypes.UPDATE_LEAGUE, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(leaguesApiResponseError(LeagueActionTypes.UPDATE_LEAGUE, error));
    }
}

function* addRefereeFee({payload}: {payload: RefereeFeeData, type: string}): SagaIterator {
    try {
		const { blockDate, leagueId } = payload;
        const data = {blockDate};
        const response = yield call(api.post, {url: `${BASE_URL}${leagueId}/request-referee-fee`, data: data});
        const created = response.data;
        yield put(leaguesApiResponseSuccess(LeagueActionTypes.ADD_REFEREE_FEE, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(leaguesApiResponseError(LeagueActionTypes.ADD_REFEREE_FEE, error));
    }
}

export function* watchGetAllLeagues() {
    yield takeEvery(LeagueActionTypes.GET_LEAGUES, getAll);
}

export function* watchFindLeague() {
    yield takeEvery(LeagueActionTypes.FIND_LEAGUE, findOne);
}

export function* watchCreateLeague() {
    yield takeEvery(LeagueActionTypes.CREATE_LEAGUE, createLeague);
}

export function* watchUpdateLeague() {
    yield takeEvery(LeagueActionTypes.UPDATE_LEAGUE, updateLeague);
}

export function* watchRefereeFee() {
    yield takeEvery(LeagueActionTypes.ADD_REFEREE_FEE, addRefereeFee);
}

function* leaguesSaga() {
    yield all([fork(watchGetAllLeagues), fork(watchFindLeague), fork(watchCreateLeague), fork(watchUpdateLeague), fork(watchRefereeFee)]);
}

export default leaguesSaga;
