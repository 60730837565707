import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { clubsApiResponseSuccess, clubsApiResponseError } from './actions';

// constants
import { ClubActionTypes } from './constants';
import Club from './model';

interface ClubData {
    payload: Club;
    type: string;
}

const api = new APICore();
const BASE_URL = '/clubs/';

/**
 * Logout the user
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(clubsApiResponseSuccess(ClubActionTypes.GET_CLUBS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(clubsApiResponseError(ClubActionTypes.GET_CLUBS, error));
    }
}

function* createClub({payload}: ClubData): SagaIterator {
    try {
		const { name, shortName, commercialName, address, CP, CIF, phone, email, city, state, active } = payload;
		const createFields = { name, shortName, commercialName, address, CP, CIF, phone, email, city, state, active };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(clubsApiResponseSuccess(ClubActionTypes.CREATE_CLUB, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(clubsApiResponseError(ClubActionTypes.CREATE_CLUB, error));
    }
}

function* updateClub({payload}: ClubData): SagaIterator {
    try {
		const { name, shortName, commercialName, address, CP, CIF, phone, email, city, state, active } = payload;
		const updateFields = { name, shortName, commercialName, address, CP, CIF, phone, email, city, state, active };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(clubsApiResponseSuccess(ClubActionTypes.UPDATE_CLUB, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(clubsApiResponseError(ClubActionTypes.UPDATE_CLUB, error));
    }
}

function* renewClub({payload}: ClubData): SagaIterator {
    try {
        const response = yield call(api.post, {url: `${BASE_URL}${payload.id ?? 0}/renew`, data: {}});
        const updated = response.data;
        yield put(clubsApiResponseSuccess(ClubActionTypes.RENEW_CLUB, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(clubsApiResponseError(ClubActionTypes.RENEW_CLUB, error));
    }
}

export function* watchGetAllClubs() {
    yield takeEvery(ClubActionTypes.GET_CLUBS, getAll);
}

export function* watchCreateClub() {
    yield takeEvery(ClubActionTypes.CREATE_CLUB, createClub);
}

export function* watchUpdateClub() {
    yield takeEvery(ClubActionTypes.UPDATE_CLUB, updateClub);
}

export function* watchRenewClub() {
    yield takeEvery(ClubActionTypes.RENEW_CLUB, renewClub);
}

function* clubsSaga() {
    yield all([fork(watchGetAllClubs), fork(watchCreateClub), fork(watchUpdateClub), fork(watchRenewClub)]);
}

export default clubsSaga;
