import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { encountersApiResponseSuccess, encountersApiResponseError } from './actions';

// constants
import { EncounterActionTypes } from './constants';
import Encounter from './model';

interface EncounterData {
    payload: Encounter;
    type: string;
}

interface EncountersData {
    payload: Encounter[];
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/encounters/';



function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'DESC' }];
        const response = yield call(api.get, {url:  `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(encountersApiResponseSuccess(EncounterActionTypes.GET_ENCOUNTERS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.GET_ENCOUNTERS, error));
    }
}

function* getDayEncounters({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'DESC' }];
        const response = yield call(api.get, {url:  `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(encountersApiResponseSuccess(EncounterActionTypes.GET_DAY_ENCOUNTERS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.GET_DAY_ENCOUNTERS, error));
    }
}

function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(encountersApiResponseSuccess(EncounterActionTypes.FIND_ENCOUNTER, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.FIND_ENCOUNTER, error));
    }
}

function* createEncounter({payload}: EncounterData): SagaIterator {
    try {
		const { day, timestamp, location, comments, leagueId, localTeamId, visitorTeamId, refereeLicenseId } = payload;
		const createFields = { day, timestamp, location, comments, leagueId, localTeamId, visitorTeamId, refereeLicenseId };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(encountersApiResponseSuccess(EncounterActionTypes.CREATE_ENCOUNTER, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.CREATE_ENCOUNTER, error));
    }
}

function* deleteEncounter({payload}: EncounterData): SagaIterator {
    try {
		const { id } = payload;
        yield call(api.delete, {url: `${BASE_URL}${id}`});
        yield put(encountersApiResponseSuccess(EncounterActionTypes.DELETE_ENCOUNTER, {}));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.DELETE_ENCOUNTER, error));
    }
}

function* importEncounters({payload}: EncountersData): SagaIterator {
    try {
        const response = yield call(api.post, {url: `${BASE_URL}import`, data: payload});
        const created = response.data;
        yield put(encountersApiResponseSuccess(EncounterActionTypes.IMPORT_ENCOUNTERS, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.IMPORT_ENCOUNTERS, error));
    }
}

function* updateEncounter({payload}: EncounterData): SagaIterator {
    try {
		const { day, timestamp, location, localPoints, visitorPoints, localRankingPointsAdjustement, visitorRankingPointsAdjustement, comments, resultsTimestamp, ABC, XYZ, leagueId, localTeamId, visitorTeamId, refereeLicenseId } = payload;
		const updateFields = { day, timestamp, location, localPoints, visitorPoints, localRankingPointsAdjustement, visitorRankingPointsAdjustement, comments, resultsTimestamp, ABC, XYZ, leagueId, localTeamId, visitorTeamId, refereeLicenseId };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(encountersApiResponseSuccess(EncounterActionTypes.UPDATE_ENCOUNTER, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.UPDATE_ENCOUNTER, error));
    }
}

function* updateEncounterResults({payload}: EncounterData): SagaIterator {
    try {
		const { timestamp, location, localPoints, visitorPoints, noShow, comments, ABC, XYZ, matches, actaImg, teamsImg } = payload;
		const resultsFields = { timestamp, location, localPoints, visitorPoints, noShow, comments, ABC, XYZ, matches };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}/results`, data: resultsFields});
        if (typeof actaImg !== 'undefined' && typeof actaImg !== 'string') {
            yield call(api.postWithFile, {url: `${BASE_URL}${payload.id ?? 0}/actaImg`, data: {avatar: actaImg}});            
        }
        if (typeof teamsImg !== 'undefined' && typeof teamsImg !== 'string') {
            yield call(api.postWithFile, {url: `${BASE_URL}${payload.id ?? 0}/teamsImg`, data: {avatar: teamsImg}});            
        }
        yield put(encountersApiResponseSuccess(EncounterActionTypes.ADD_ENCOUNTER_RESULTS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(encountersApiResponseError(EncounterActionTypes.ADD_ENCOUNTER_RESULTS, error));
    }
}

export function* watchGetAllEncounters() {
    yield takeEvery(EncounterActionTypes.GET_ENCOUNTERS, getAll);
}

export function* watchGetDayEncounters() {
    yield takeEvery(EncounterActionTypes.GET_DAY_ENCOUNTERS, getDayEncounters);
}

export function* watchFindEncounter() {
    yield takeEvery(EncounterActionTypes.FIND_ENCOUNTER, findOne);
}

export function* watchCreateEncounter() {
    yield takeEvery(EncounterActionTypes.CREATE_ENCOUNTER, createEncounter);
}

export function* watchDeleteEncounter() {
    yield takeEvery(EncounterActionTypes.DELETE_ENCOUNTER, deleteEncounter);
}

export function* watchImportEncounters() {
    yield takeEvery(EncounterActionTypes.IMPORT_ENCOUNTERS, importEncounters);
}

export function* watchUpdateEncounter() {
    yield takeEvery(EncounterActionTypes.UPDATE_ENCOUNTER, updateEncounter);
}

export function* watchUpdateEncounterResults() {
    yield takeEvery(EncounterActionTypes.ADD_ENCOUNTER_RESULTS, updateEncounterResults);
}

function* encountersSaga() {
    yield all([fork(watchGetAllEncounters), fork(watchGetDayEncounters), fork(watchFindEncounter), fork(watchCreateEncounter), fork(watchDeleteEncounter), fork(watchImportEncounters), fork(watchUpdateEncounter), fork(watchUpdateEncounterResults)]);
}

export default encountersSaga;
