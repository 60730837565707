export enum OrderActionTypes {
    API_RESPONSE_SUCCESS = '@@orders/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@orders/API_RESPONSE_ERROR',

    GET_ORDERS = '@@orders/GET_ORDERS',
    FIND_ORDER = '@@orders/FIND_ORDER',
    UPDATE_ORDER = '@@orders/UPDATE_ORDER',
    DELETE_ORDER = '@@orders/DELETE_ORDER',

    GET_SHOPPING_CARTS = '@@orders/GET_SHOPPING_CARTS',

    RESET = '@@orders/RESET',
    GET_STATISTICS = '@@orders/GET_STATISTICS',
}
