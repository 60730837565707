export enum ConstantActionTypes {
    API_RESPONSE_SUCCESS = '@@constants/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@constants/API_RESPONSE_ERROR',

    GET_CONSTANTS = '@@constants/GET_CONSTANTS',
    FIND_CONSTANT = '@@constants/FIND_CONSTANT',
    UPDATE_CONSTANT = '@@constants/UPDATE_CONSTANT',
    DELETE_CONSTANT = '@@constants/DELETE_CONSTANT',
    CREATE_CONSTANT = '@@constants/CREATE_CONSTANT',
}
