// constants
import { AgeGroupActionTypes } from './constants';
import AgeGroup from './model';

export interface AgeGroupActionType {
    type:
        | AgeGroupActionTypes.API_RESPONSE_SUCCESS
        | AgeGroupActionTypes.API_RESPONSE_ERROR
        | AgeGroupActionTypes.GET_AGE_GROUPS
    payload: {} | string;
}

// common success
export const ageGroupsApiResponseSuccess = (actionType: string, data: AgeGroup | AgeGroup[] | {} | []): AgeGroupActionType => ({
    type: AgeGroupActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ageGroupsApiResponseError = (actionType: string, error: string): AgeGroupActionType => ({
    type: AgeGroupActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getageGroups = (): AgeGroupActionType => ({
    type: AgeGroupActionTypes.GET_AGE_GROUPS,
    payload: {},
});

