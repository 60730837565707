// constants
import { LicenseTypeActionTypes } from './constants';
import LicenseType from './model';

const INIT_STATE = {
    licenseTypes: [],
    loading: false,
    requestStatus: 0,
};

interface LicenseTypeActionType {
    type:
        | LicenseTypeActionTypes.API_RESPONSE_SUCCESS
        | LicenseTypeActionTypes.API_RESPONSE_ERROR
        | LicenseTypeActionTypes.GET_LICENSE_TYPES
    payload: {
        actionType?: string;
        data?: LicenseType | {};
        error?: string;
    };
}

interface State {
    licenseTypes?: LicenseType[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const LicenseTypeReducer = (state: State = INIT_STATE, action: LicenseTypeActionType): any => {
    switch (action.type) {
        case LicenseTypeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LicenseTypeActionTypes.GET_LICENSE_TYPES: {
                    return {
                        ...state,
                        licenseTypes: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case LicenseTypeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case LicenseTypeActionTypes.GET_LICENSE_TYPES:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default LicenseTypeReducer;
