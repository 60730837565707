// constants
import { FilterType } from '../../helpers/api/apiCore';
import { TeamActionTypes } from './constants';
import Team from './model';

export interface TeamActionType {
    type:
        | TeamActionTypes.API_RESPONSE_SUCCESS
        | TeamActionTypes.API_RESPONSE_ERROR
        | TeamActionTypes.GET_TEAMS
        | TeamActionTypes.FIND_TEAM
        | TeamActionTypes.CREATE_TEAM
        | TeamActionTypes.DELETE_TEAM
        | TeamActionTypes.UPDATE_TEAM
        | TeamActionTypes.REGISTER_TEAM
    payload: {} | string;
}

// common success
export const teamsApiResponseSuccess = (actionType: string, data: Team | Team[] | {} | []): TeamActionType => ({
    type: TeamActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const teamsApiResponseError = (actionType: string, error: string): TeamActionType => ({
    type: TeamActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTeams = (filter: FilterType): TeamActionType => ({
    type: TeamActionTypes.GET_TEAMS,
    payload: filter,
});

export const findTeam = (teamId: number, includeDeleted: boolean = false): TeamActionType => ({
    type: TeamActionTypes.FIND_TEAM,
    payload: {teamId, includeDeleted},
});

export const deleteTeam = (teamId: number): TeamActionType => ({
    type: TeamActionTypes.DELETE_TEAM,
    payload: teamId,
});

export const createTeam = (team: Team): TeamActionType => ({
    type: TeamActionTypes.CREATE_TEAM,
    payload: team,
});

export const updateTeam = (team: Team): TeamActionType => ({
    type: TeamActionTypes.UPDATE_TEAM,
    payload: team,
});

export const registerTeam = (team: Team): TeamActionType => ({
    type: TeamActionTypes.REGISTER_TEAM,
    payload: team,
});
