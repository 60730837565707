import React, { useEffect } from 'react';
import { withSwal } from 'react-sweetalert2';
import { useTranslation } from 'react-i18next';

export default withSwal((props: any) => {
    const { t } = useTranslation();
    const { swal, showPopup, onClose } = props;

    useEffect(() => {
        if (showPopup === 403) {
            swal.fire({
                title: t('Acceso denegado'),
                text: t('No tienes los permisos necesarios para realizar esta acción'),
                icon: 'error',
            }).then(() => {
                if (onClose) onClose();
            });
        }
    }, [showPopup, swal, t, onClose]);
    
    return (<></>);
});