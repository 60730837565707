export enum RegistrationActionTypes {
    API_RESPONSE_SUCCESS = '@@registrations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@registrations/API_RESPONSE_ERROR',

    GET_REGISTRATIONS = '@@registrations/GET_REGISTRATIONS',
    GET_AVAILABLE_REGISTRATIONS = '@@registrations/GET_AVAILABLE_REGISTRATIONS',
    FIND_REGISTRATION = '@@registrations/FIND_REGISTRATION',
    UPDATE_REGISTRATION = '@@registrations/UPDATE_REGISTRATION',
    CREATE_REGISTRATION = '@@registrations/CREATE_REGISTRATION',
    DELETE_REGISTRATION = '@@registrations/DELETE_REGISTRATION',

    RESET = '@@registrations/RESET',
}
