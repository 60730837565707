// constants
import { FilterType } from '../../helpers/api/apiCore';
import { MatchActionTypes } from './constants';
import Match from './model';

export interface MatchActionType {
    type:
        | MatchActionTypes.API_RESPONSE_SUCCESS
        | MatchActionTypes.API_RESPONSE_ERROR
        | MatchActionTypes.GET_MATCHES
        | MatchActionTypes.FIND_MATCH
        | MatchActionTypes.CREATE_MATCH
        | MatchActionTypes.UPDATE_MATCH
    payload: {} | string;
}

// common success
export const matchesApiResponseSuccess = (actionType: string, data: Match | Match[] | {} | []): MatchActionType => ({
    type: MatchActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const matchesApiResponseError = (actionType: string, error: string): MatchActionType => ({
    type: MatchActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getMatches = (filter: FilterType): MatchActionType => ({
    type: MatchActionTypes.GET_MATCHES,
    payload: filter,
});

export const findMatch = (matchId: number): MatchActionType => ({
    type: MatchActionTypes.FIND_MATCH,
    payload: matchId,
});

export const createMatch = (match: Match): MatchActionType => ({
    type: MatchActionTypes.CREATE_MATCH,
    payload: match,
});

export const updateMatch = (match: Match): MatchActionType => ({
    type: MatchActionTypes.UPDATE_MATCH,
    payload: match,
});
