// constants
import { PaymentActionTypes } from './constants';
import Payment from './model';

const INIT_STATE = {
    persons: [],
    loading: false,
    requestStatus: 0,
};

interface PaymentActionType {
    type:
        | PaymentActionTypes.API_RESPONSE_SUCCESS
        | PaymentActionTypes.API_RESPONSE_ERROR
        | PaymentActionTypes.REQUEST_PAYMENT
        | PaymentActionTypes.GET_REDIRECT_PARAMS
        | PaymentActionTypes.GET_INSITE_PARAMS
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    };
}

interface State {
    payment?: Payment | {};
    result?: any;
    redirectParams?: any;
    insiteParams?: any;
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const PaymentReducer = (state: State = INIT_STATE, action: PaymentActionType): any => {
    switch (action.type) {
        case PaymentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PaymentActionTypes.REQUEST_PAYMENT: {
                    return {
                        ...state,
                        result: action.payload.data,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case PaymentActionTypes.GET_REDIRECT_PARAMS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        redirectParams: action.payload.data,
                    };
                }
                case PaymentActionTypes.GET_INSITE_PARAMS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                        insiteParams: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case PaymentActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case PaymentActionTypes.REQUEST_PAYMENT:
            return { ...state, loading: true, requestStatus: 0, payment: action.payload};
        case PaymentActionTypes.GET_REDIRECT_PARAMS:
            return { ...state, loading: true, requestStatus: 0, payment: action.payload};
        case PaymentActionTypes.GET_INSITE_PARAMS:
            return { ...state, loading: true, requestStatus: 0, payment: action.payload};
        default:
            return { ...state };
    }
};

export default PaymentReducer;
