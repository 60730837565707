export enum LeagueActionTypes {
    API_RESPONSE_SUCCESS = '@@leagues/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@leagues/API_RESPONSE_ERROR',

    GET_LEAGUES = '@@leagues/GET_LEAGUES',
    FIND_LEAGUE = '@@leagues/FIND_LEAGUE',
    UPDATE_LEAGUE = '@@leagues/UPDATE_LEAGUE',
    DELETE_LEAGUE = '@@leagues/DELETE_LEAGUE',
    CREATE_LEAGUE = '@@leagues/CREATE_LEAGUE',

    ADD_REFEREE_FEE = '@@leagues/ADD_REFEREE_FEE',
}
