import { createNumberMask } from 'text-mask-addons';
import AgeGroup from '../redux/ageGroup/model';
import Person from '../redux/person/model';
import { mysqlDateStrToDate, mysqlDatetimeStrToDate } from './dates';

export * from './array';
export * from './layout';
export * from './listToPDF';
export * from './dates';

export const notValidCSS: React.CSSProperties = {
    borderColor: "#f86262",
    paddingRight: "calc(1.5em + 0.9rem)",
    backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f86262'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f86262' stroke='none'/%3e%3c/svg%3e")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 0.225rem) center',
    backgroundSize: 'calc(0.75em + 0.45rem) calc(0.75em + 0.45rem)',
};

export const validCSS: React.CSSProperties = {
    borderColor: "#44cf9c",
    paddingRight: "calc(1.5em + .9rem)",
    backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2344cf9c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right calc(0.375em + 0.225rem) center',
    backgroundSize: 'calc(0.75em + 0.45rem) calc(0.75em + 0.45rem)',
};

export const priceToStr = (price?: number) => {
    if (price) {
        return `${Math.floor(price/100)},${price%100 > 9 ? price%100 : `0${price%100}`}`;
    } else return "0,00";
}

export const priceMask = createNumberMask({
    prefix: "",
    suffix: "€",
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    requireDecimal: true,
});

const EXPIRATION_DAY = 31;
export const EXPIRATION_MONTH = 7; // número del mes -1, porque empiezan en índice 0

export const getExpirationDate = (): Date => {
    const today: Date = new Date();
    const expirationYear = today.getFullYear() + ((today.getMonth() > EXPIRATION_MONTH || (today.getMonth() === EXPIRATION_MONTH && today.getDate() > EXPIRATION_DAY)) ? 1 : 0);
    return new Date(expirationYear, EXPIRATION_MONTH, EXPIRATION_DAY);
}

export const checkWithinSeason = (date: Date): boolean => {
    const expirationDate = getExpirationDate();
    expirationDate.setFullYear(expirationDate.getFullYear() - 1);
    return date > expirationDate;
}

export const getPersonAgeInCurrentSeason = (date: Date | string): number => {
    const fecha = (typeof date === 'string' ? mysqlDateStrToDate(date) : date);
    const today: Date = new Date();
    const seasonYear = today.getFullYear() - ((today.getMonth() > EXPIRATION_MONTH || (today.getMonth() === EXPIRATION_MONTH && today.getDate() > EXPIRATION_DAY)) ? 0 : 1);
    return seasonYear - fecha.getFullYear();
}

export const getPersonAgeGroup = (ageGroups: AgeGroup[], person?: Person) => {
    if (!person) return '?';
    const personAge = getPersonAgeInCurrentSeason(person.birthDate);
    return ageGroups.find((ageGroup: AgeGroup) => (!ageGroup.minAge || parseInt(ageGroup.minAge) <= personAge) && (!ageGroup.maxAge || parseInt(ageGroup.maxAge) >= personAge))?.name ?? '?'
}

export const getLeagueSeason = (createdAt: string, openRegistrationMonth: string, openRegistrationDay: string = `${EXPIRATION_DAY}`) => {
    const leagueDate = mysqlDatetimeStrToDate(createdAt); 
    const startYear: number = leagueDate.getMonth() > (parseInt(openRegistrationMonth) - 1) ? leagueDate.getFullYear() : 
                    (leagueDate.getMonth() === (parseInt(openRegistrationMonth) - 1) && leagueDate.getDate() > parseInt(openRegistrationDay)) ? leagueDate.getFullYear() :
                    leagueDate.getFullYear() - 1;
    return `${startYear}-${startYear+1}`;
};

export const numberFormat = (num: number) => {
    return num.toLocaleString('es-ES', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}

export const trimAndAddEllipsis = (text: string, maxChars: number) => {
    if (text.length > maxChars) {
        return `${text.substring(0, maxChars)}...`;
    }
    return text;
  };

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
