import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { categoriesApiResponseSuccess, categoriesApiResponseError } from './actions';

// constants
import { CategoryActionTypes } from './constants';

const api = new APICore();
const BASE_URL = '/categories/';

/**
 * Get all categories
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(categoriesApiResponseSuccess(CategoryActionTypes.GET_CATEGORIES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(categoriesApiResponseError(CategoryActionTypes.GET_CATEGORIES, error));
    }
}

export function* watchGetAllCategories() {
    yield takeEvery(CategoryActionTypes.GET_CATEGORIES, getAll);
}


function* categoriesSaga() {
    yield all([fork(watchGetAllCategories)]);
}

export default categoriesSaga;
