export enum RankingActionTypes {
    API_RESPONSE_SUCCESS = '@@rankings/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@rankings/API_RESPONSE_ERROR',

    GET_RANKING = '@@rankings/GET_RANKING',
    GET_RANKINGS = '@@rankings/GET_RANKINGS',
    FIND_RANKING = '@@rankings/FIND_RANKING',
    UPDATE_RANKING = '@@rankings/UPDATE_RANKING',
    DELETE_RANKING = '@@rankings/DELETE_RANKING',
    CREATE_RANKING = '@@rankings/CREATE_RANKING',

    RENEW_RANKING = '@@rankings/RENEW_RANKING',
}
