export enum TeamActionTypes {
    API_RESPONSE_SUCCESS = '@@teams/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@teams/API_RESPONSE_ERROR',

    GET_TEAMS = '@@teams/GET_TEAMS',
    FIND_TEAM = '@@teams/FIND_TEAM',
    UPDATE_TEAM = '@@teams/UPDATE_TEAM',
    DELETE_TEAM = '@@teams/DELETE_TEAM',
    CREATE_TEAM = '@@teams/CREATE_TEAM',

    REGISTER_TEAM = '@@teams/REGISTER_TEAM',
}
