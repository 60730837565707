import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { RfetmLicensesApiResponseSuccess, RfetmLicensesApiResponseError } from './actions';

// constants
import { RFETMLicenseActionTypes } from './constants';

const api = new APICore();
const BASE_URL = '/licenses/';

/**
 * Find RFETM License data
 */
function* findOne({payload}: {payload: string, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}/rfetm`});
        yield put(RfetmLicensesApiResponseSuccess(RFETMLicenseActionTypes.GET_RFETM_LICENSES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(RfetmLicensesApiResponseError(RFETMLicenseActionTypes.GET_RFETM_LICENSES, error));
    }
}

export function* watchGetAllRFETMLicenses() {
    yield takeEvery(RFETMLicenseActionTypes.GET_RFETM_LICENSES, findOne);
}


function* RFETMSaga() {
    yield all([fork(watchGetAllRFETMLicenses)]);
}

export default RFETMSaga;
